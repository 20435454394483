<template>
  <v-file-input
    :accept="accept"
    label="选择文件"
    persistent-hint
    ref="file-input"
    @change="handleFileChange"
    :rules="rules"
  ></v-file-input>
</template>
<script>
export default {
  props: {
    accept: {
      type: String,
      default: '.xlsx, .xls'
    },
    rules: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {}
  },
  mounted() {
    const dropbox = this.$refs['file-input'].$el
    dropbox.addEventListener('drop', this.enentDrop, false)
    dropbox.addEventListener('dragleave', (e) => {
      e.stopPropagation()
      e.preventDefault()
    })
    dropbox.addEventListener('dragenter', (e) => {
      e.stopPropagation()
      e.preventDefault()
    })
    dropbox.addEventListener('dragover', (e) => {
      e.stopPropagation()
      e.preventDefault()
    })
  },
  methods: {
    enentDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      this.handleFileChange(e.dataTransfer.files[0])
    },
    handleFileChange(e) {
      this.$refs['file-input'].lazyValue = e
      this.$emit('change', e)
    }
  }
}
</script>