





































































































































































































































































































































































import Vue from 'vue'
import nf from '@/utils/notification'

export default Vue.extend({
    name: 'TemplateDownload',

    data: () => ({

    }),
    mounted() {
    }

})
