<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-0">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="11">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="编号/状态"
              hide-details
              @keyup.enter.native="doSearch"
              clearable
              @click:clear="doSearchClear"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-dialog v-model="dialog_delete" persistent max-width="320">
              <v-card v-if="dialog_delete">
                <v-card-title class="subtitle-2"
                  >您确认要删除该记录吗?</v-card-title
                >
                <v-card-text
                  >删除后该记录将无法还原，请您谨慎操作！</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click.native="dialog_delete = false"
                    >取消</v-btn
                  >
                  <v-btn color="error" text @click.native="doDelete"
                    >我想好了，删</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog_download" persistent max-width="320">
              <v-card v-if="dialog_download">
                <v-card-text
                  >正在下载文件，请稍等...</v-card-text
                >
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="getHeaders"
              :items="items"
              :options.sync="pagination"
              :server-items-length="totalItems"
              :loading="loading"
              :expanded.sync="expanded"
              single-expand
              show-expand
              item-key="sid"
              locale="zh-CH"
              :footer-props="{
                itemsPerPageText: '每页行数：',
                itemsPerPageOptions: pageItems
              }"
            >
              <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row dense>
                  <v-col
                    v-for="detail in item.details"
                    :key="detail.individual_no"
                    cols="12"
                  >
                    <span class="caption">姓名：{{ detail.individual_name }}；客户编号：{{
                        detail.individual_no}}；报告编号：{{ detail.report_no | getLastEleven}}</span>
                  </v-col>
                </v-row>
              </td>
            </template>
              <template v-slot:item.created_time="{ item }">
                <v-label>{{ item.created_time | formatTimeLong }}</v-label>
              </template>
              <template v-slot:item.created_by="{ item }">
                <v-label>{{ item.created_by.first_name }}</v-label>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-3"
                  v-if="item.file_url.length > 0"
                  @click="downloadItem(item)"
                  color="green"
                >
                  mdi-download
                </v-icon>
                <v-icon small color="red" @click="doDeleteItem(item)" v-if="item.status!=='ongoing'"
                  >mdi-delete</v-icon
                >
              </template>
              <template slot="no-data">
                <p class="text-xs-center grey--text">对不起，暂时无数据</p>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";
export default {
  name: "ReportDownloadRecord",
  data() {
    return {
      dialog: false,
      dialog_delete: false,
      dialog_download: false,
      valid: true,
      deleteItem: {},
      totalItems: 0,
      items: [],
      org: {},
      actual_individual_tags: [],
      tags: {},
      loading: true,
      pagination: {},
      pageItems: [10, 20, 50],
      search: "",
      records: [],
      nextUrl: "",
      totalCount: 0,
      expanded: [],
      record_fields: [
        {
          value: "download_no",
          text: "编号",
          sortable: true,
          breakpoints: []
        },

        {
          value: "desc",
          text: "描述",
          sortable: true,
          breakpoints: []
        },
        {
          value: "status",
          text: "状态",
          sortable: true,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "download_count",
          text: "下载次数",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "created_time",
          text: "操作日期",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "created_by",
          text: "创建人",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        { text: "操作", value: "actions", sortable: false, breakpoints: [] },
        {
          value: "data-table-expand",
          text: "",
          sortable: false,
          breakpoints: []
        }
      ],
      radios: 0
    };
  },
  computed: {
    getHeaders() {
      let headers = [];
      for (let item of this.record_fields) {
        if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
          headers.push(item);
        }
      }
      return headers;
    }
  },
  watch: {
    pagination: {
      async handler() {
        const { page, itemsPerPage } = this.pagination;
        if (this.nextUrl && this.records.length < page * itemsPerPage) {
          this.loading = true;
          let url = this.nextUrl.split("?");
          try {
            let result = await api.get(
              "report_download_record/?" + url[url.length - 1],
              this.search
            );
            for (let item of result["results"]) {
              this.records.push(item);
            }
            this.nextUrl = result["next"];
            this.totalCount = result["count"];
            this.updateDataTable(this.records, this.totalCount);
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        } else {
          this.updateDataTable(this.records, this.totalCount);
        }
      },
      deep: true
    }
  },
  mounted() {
    this.doLoadData();
  },
  methods: {
    async doLoadData() {
      this.loading = true;
      try {
        let result = await api.get("report_download_record/?");
        this.loading = false;
        this.records = result["results"];
        this.nextUrl = result["next"];
        this.totalCount = result["count"];
        this.updateDataTable(this.records, this.totalCount);
      } catch (error) {
        this.loading = false;
      }
    },
    updateDataTable(records, count) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let items = records;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy[0]];
          const sortB = b[sortBy[0]];

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }
      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      }
      this.items = items;
      this.totalItems = count;
    },

    async doSearch() {
      this.loading = true;
      try {
        let result = await api.get(
          "report_download_record/?search=" + this.search
        );
        this.loading = false;
        this.records = result["results"];
        this.nextUrl = result["next"];
        this.totalCount = result["count"];
        this.updateDataTable(this.records, this.totalCount);
      } catch (error) {
        this.loading = false;
      }
    },
    doSearchClear() {
      this.search = "";
      this.doSearch();
    },
    async downloadItem(item) {
      await api.download({
        method: 'get',
        name: item.desc,
        url: `/api/report_download_record/${item.sid}/download_report/`,
        filename: `${item.download_no}.zip`
      })
    },
    doDeleteItem(Item) {
      this.deleteItem = Item;
      this.dialog_delete = true;
    },
    async doDelete() {
      let result = await api.delete(
        "report_download_record/" + this.deleteItem.sid + "/?"
      );
      await this.doLoadData();
      this.dialog_delete = false
    }
  }
};
</script>
