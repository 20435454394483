import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from "@/utils/auth"

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import DataImportRecord from "@/views/data_import/DataImportRecord.vue";
import NewImportRecord from "@/views/data_import/NewImportRecord.vue";
import ViewImportRecord from "@/views/data_import/ViewImportRecord.vue";

import IndividualRecord from "@/views/report_generate/IndividualRecord.vue";
import DownloadRecord from "@/views/report_download/ReportDownloadRecord.vue";
import PublishRecord from "@/views/report_publish/ReportRecord.vue";

import DataExportRecord from "@/views/data_export/DataExport.vue";

import Tools from "@/views/tools/index.vue"

import LogRecord from "@/views/logs/LogRecord.vue";
import ViewLog from "@/views/logs/ViewLog.vue";

import MedicineRecord from "@/views/medicines/MedicineRecord.vue";
import ViewMedicine from "@/views/medicines/ViewMedicine.vue";

import CustomerSurvey from "@/views/survey/Index.vue";

import Container from '@/views/Container.vue'

function requireAuth(to: any, from: any, next: any) {
    let result = auth.loggedIn()

    if (!result) {
        next({
            path: "/login",
            query: {
                redirect: to.fullPath
            }
        });
    } else {
        next();
    }
}

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login
    }
];

export const fixed_menu = {
    path: "/",
    name: "container",
    component: Container,
    redirect: "/home",
    children: [
        {
            path: "home",
            name: "首页",
            component: Home,
            beforeEnter: requireAuth
        },
        // {
        //     path: "/about",
        //     name: "关于",
        //     // route level code-splitting
        //     // this generates a separate chunk (about.[hash].js) for this route
        //     // which is lazy-loaded when the route is visited.
        //     component: () =>
        //         import(/* webpackChunkName: "about" */ "../views/About.vue")
        // }
    ]
}
export const dynamic_menu = {
    '/data_import': {
        path: "/data_import",
        name: "数据导入管理",
        redirect: "/data_import/list",
        component: {
            render(c: any) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "list",
                name: "记录列表",
                component: DataImportRecord,
                beforeEnter: requireAuth,
                meta: {
                    keepAlive: true // 需要被缓存
                }
            },
            {
                path: "new_data_record",
                name: "新增记录",
                component: NewImportRecord,
                beforeEnter: requireAuth
            },
            {
                path: "view_data_record/:id",
                name: "查看导入记录",
                component: ViewImportRecord,
                beforeEnter: requireAuth,
                props: true
            }
        ]
    },
    '/report_generate': {
        path: "/report_generate",
        name: "报告生成管理",
        redirect: "/report_generate/list",
        component: {
            render(c: any) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "list",
                name: "客户列表",
                component: IndividualRecord,
                beforeEnter: requireAuth
            }
        ]
    },
    '/report_download': {
        path: "/report_download",
        name: "报告下载管理",
        redirect: "/report_download/list",
        component: {
            render(c: any) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "list",
                name: "下载列表",
                component: DownloadRecord,
                beforeEnter: requireAuth
            }
        ]
    },
    '/report_publish': {
        path: "/report_publish",
        name: "报告发布管理",
        redirect: "/report_publish/list",
        component: {
            render(c: any) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "list",
                name: "报告列表",
                component: PublishRecord,
                beforeEnter: requireAuth
            }
        ]
    },
    '/data_export': {
        path: "/data_export",
        name: "数据导出管理",
        redirect: "/data_export/list",
        component: {
            render(c: any) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "list",
                name: "数据导出",
                component: DataExportRecord,
                beforeEnter: requireAuth
            }
        ]
    },
    '/tools': {
        path: "/tools",
        name: "常用工具",
        redirect: "/tools/dc",
        component: {
            render(c: any) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "dc",
                name: "数据转换",
                component: Tools,
                beforeEnter: requireAuth
            }
        ]
    },
    '/logs': {
        path: "/logs",
        name: "操作日志查询",
        redirect: "/logs/list",
        component: {
            render(c: any) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "list",
                name: "日志查询",
                component: LogRecord,
                beforeEnter: requireAuth,
                meta: {
                    keepAlive: true // 需要被缓存
                }
            },
            {
                path: "view_log/:id",
                name: "查看日志信息",
                component: ViewLog,
                beforeEnter: requireAuth
            }
        ]
    },
    '/medicines': {
        path: "/medicines",
        name: "营养素查询",
        redirect: "/medicines/list",
        component: {
            render(c: any) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "list",
                name: "营养素信息",
                component: MedicineRecord,
                beforeEnter: requireAuth,
                meta: {
                    keepAlive: true // 需要被缓存
                }
            },
            {
                path: ":id",
                name: "营养素明细",
                component: ViewMedicine,
                beforeEnter: requireAuth
            }
        ]
    },
    // '/survey': {
    //     path: "/survey",
    //     name: "满意度调查",
    //     redirect: "/survey/list",
    //     component: {
    //         render(c: any) {
    //             return c("router-view");
    //         }
    //     },
    //     children: [
    //         {
    //             path: "list",
    //             name: "满意度调查记录",
    //             component: CustomerSurvey,
    //             beforeEnter: requireAuth,
    //             meta: {
    //                 keepAlive: true // 需要被缓存
    //             }
    //         }
    //     ]
    // },
    // "/charater_calculate_config": {
    //     path: "/charater_calculate_config",
    //     name: "特征计算配置",
    //     component: () =>
    //         import("../views/charater_calculate_config/group.vue")
    // },
}

const router = new VueRouter({
    mode: 'hash',
    linkActiveClass: 'open active',
    routes
})

export default router
