<template>
  <v-breadcrumbs class="pl-3 pb-8 pt-1" :items="list">
    <v-icon slot="divider" >chevron_right</v-icon>
    <template slot="item" slot-scope="props">
        <span class="active content--text body-2" v-if="isLast(props.item)">{{ showName(props.item) }}</span>
        <router-link :to="props.item" class="v-breadcrumbs__item body-2" v-else>{{ showName(props.item) }}</router-link>
    </template>
  </v-breadcrumbs>
</template>


<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    isLast(item) {
      return this.list[this.list.length - 1].name == item.name;
      // return index === this.list.length - 1;
    },
    showName(item) {
      if (item.meta && item.meta.label) {
        item = item.meta && item.meta.label;
      }
      if (item.name) {
        item = item.name;
      }
      return item;
    }
  }
};
</script>
