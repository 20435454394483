<template>
	<v-card>
		<v-card-text>
			<v-row>
				<v-col cols="12" class="py-0">
					<v-textarea v-model="nos" hint="多个客户编号之间请用逗号“,”分隔，如：C201900001,C201900002" persistent-hint label="客户编号"
											rows=2 no-resize></v-textarea>
				</v-col>
				<v-col cols="12" class="py-0">
					<v-radio-group v-model="export_type" row hide-details>
						<v-radio label="全部数据" value="all"></v-radio>
						<v-radio label="最新数据" value="last"></v-radio>
					</v-radio-group>
				</v-col>
				<v-col cols="12" class="py-0">
					<v-autocomplete
          v-model="select_templates"
          :items="templates"
          item-text="template_name"
          item-value="sid"
          label="模版"
          multiple
          chips
        ></v-autocomplete>
				</v-col>
				<v-col cols="12">
					<v-btn
						color="primary"
						class="mr-2"
						:loading="loading"
						@click.stop="doSearch"
					>
						查询
					</v-btn
					>
					<v-btn
						color="primary"
						class="mr-2"
						:loading="loading"
						:disabled="items.length===0"
						@click.stop="doExport"
					>
						导出
					</v-btn
					>
				</v-col>
				<v-col>
					<v-data-table
						:headers="getHeaders"
						:items="items"
						:loading="loading"
						:options.sync="pagination"
						locale="zh-CH"
						:footer-props="{
                itemsPerPageText: '每页行数：',
                itemsPerPageOptions: pageItems
              }"
					>
						<template slot="no-data">
							<p class="text-xs-center grey--text">对不起，暂时无数据</p>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
  import {api} from "@/utils/api";
  import nf from "@/utils/notification";
  import XLSX from "xlsx";

  export default {
    name: "AllDataExport",
    data() {
      return {
        nos: '',
        dialog: false,
        export_type: 'all',
        select_templates: [],
        valid: true,
        totalItems: 0,
        items:[],
        org: {},
        actual_individual_tags: [],
        templates: [],
        loading: false,
        pagination: {},
        pageItems: [10, 50, 100],
        search: "",
        records: [],
        nextUrl: "",
        totalCount: 0,
        record_fields: [
          {
            value: "individual_no",
            text: "编号",
            sortable: false,
            breakpoints: []
          },

          {
            value: "individual_name",
            text: "姓名",
            sortable: false,
            breakpoints: []
          },
          {
            value: "individual_gender",
            text: "性别",
            sortable: false,
            breakpoints: ["xs", "sm"]
          },
          {
            value: "individual_birthday",
            text: "出生日期",
            sortable: false,
            breakpoints: ["xs", "sm"]
          },
          {
            value: "org_name",
            text: "机构名称",
            sortable: false,
            breakpoints: ["xs", "sm"]
          },
          {
            value: "template_name",
            text: "分类",
            sortable: false,
            breakpoints: ["xs", "sm"]
          },
          {
            value: "happen_date",
            text: "发生日期",
            sortable: false,
            breakpoints: ["xs", "sm"]
          },
          {
            value: "character_name",
            text: "指标",
            sortable: false,
            breakpoints: ["xs", "sm"]
          },
          {
            value: "instance_value",
            text: "值",
            sortable: false,
            breakpoints: ["xs", "sm"]
          },
        ]
      };
    },
    computed: {
      getHeaders() {
        let headers = [];
        for (let item of this.record_fields) {
          if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
            headers.push(item);
          }
        }
        return headers;
      }
    },
    async mounted() {
      this.templates = await api.get('character_templates/get_export_template_info/?', null)
    },
    methods: {
      async doSearch() {
        if (this.nos.length === 0) {
          nf.showErrorMsg('请输入客户编号')
          return
        }
        if (this.select_templates.length === 0) {
          nf.showErrorMsg('请至少选择一个模版')
          return
        }
        this.loading = true;
        try {
          let result = await api.post(
            "template_instances/get_instance_export_data/?", {
              nos: this.nos,
              export_type: this.export_type,
              templates: this.select_templates
            }
          );
          this.loading = false;
          this.items = result;
          this.pagination.page = 1
        } catch (error) {
          this.loading = false;
        }
      },
      async doExport() {
        await api.post(
            "template_instances/record_data_export_log/?", {
              nos: this.nos,
              export_type: this.export_type,
              templates: this.select_templates
            })
        let export_data = []
        for (let item of this.items) {
          let export_item = {
            '客户编号': '',
            '姓名': '',
            '性别': '',
            '出生日期': '',
            '机构名称': '',
            '分类': '',
            '发生日期': '',
            '指标': '',
            '值': '',
          }
          export_item['客户编号'] = item.individual_no;
          export_item['姓名'] = item.individual_name;
          export_item['性别'] = item.individual_gender === "MALE" ? "男" : "女";
          export_item['出生日期'] = item.individual_birthday;
          export_item['机构名称'] = item.org_name;
          export_item['分类'] = item.template_name;
          export_item['发生日期'] = item.happen_date;
          export_item['指标'] = item.character_name;
          export_item['值'] = item.instance_value;
          export_data.push(export_item)
        }
        const ws = XLSX.utils.json_to_sheet(export_data, {header: ["客户编号", "姓名", "性别", "出生日期", "机构名称", "分类", "发生日期", "指标", "值"]});
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "数据");
        /* generate file and send to client */
        XLSX.writeFile(wb, "all_instance_data.xlsx");
      }
    }
  };
</script>
