<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-radio-group v-model="query_type" row hide-details>
						<v-radio label="按姓名" value="name"></v-radio>
						<v-radio label="按机构" value="org"></v-radio>
            <v-radio label="按导入编号" value="import_no"></v-radio>
					</v-radio-group>
        </v-col>
        <v-col cols="12" class="py-0" v-if="query_type==='name'">
          <v-textarea
            v-model="names"
            hint="多个姓名之间请用逗号“,”分隔，如：张三,李四"
            persistent-hint
            label="客户姓名"
            rows="2"
            no-resize
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0" v-if="query_type==='org'">
          <v-autocomplete
              v-model="select_org"
              :items="orgs"
              item-text="org_name"
              item-value="sid"
              label="机构"
              persistent-hint
              return-object
              single-line
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0" v-if="query_type==='org'">
					<v-row>
						<v-col v-for="tag in tags" :key="tag.sid" class="py-0">
							<v-checkbox v-model="select_tags" :label="tag.tag_name" :value="tag.sid" color="primary"
													class="py-0"></v-checkbox>
						</v-col>
					</v-row>
				</v-col>
         <v-col cols="12" class="py-0" v-if="query_type==='import_no'">
           <v-text-field
            v-model="import_no"
            label="数据导入编号"
            hide-details
            clearable
          ></v-text-field>
         </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            class="mr-2"
            :loading="loading"
            @click.stop="doSearch"
          >
            查询</v-btn
          >
          <v-btn
            color="primary"
            class="mr-2"
            :loading="loading"
            :disabled="items.length === 0"
            @click.stop="doExport"
          >
            导出</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="individual_ids"
            persistent-hint
            label="客户编号"
            rows="2"
            no-resize
            readonly
            v-if="individual_ids.length > 0"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-data-table
            :headers="getHeaders"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :loading="loading"
            locale="zh-CH"
            :footer-props="{
              itemsPerPageText: '每页行数：',
              itemsPerPageOptions: pageItems,
            }"
          >
            <template v-slot:item.org_name="{ item }">
              <v-label>{{ item.organization.org_name }}</v-label>
            </template>
            <template v-slot:item.individual_gender="{ item }">
              <v-label>{{ item.individual_gender === "MALE" ? "男" : "女" }}</v-label>
            </template>
            <template v-slot:item.created_time="{ item }">
              <v-label>{{ item.created_time | formatDateDash}}</v-label>
            </template>
            <template slot="no-data">
              <p class="text-xs-center grey--text">对不起，暂时无数据</p>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";
import XLSX from "xlsx";

export default {
  name: "CustomerExport",
  data() {
    return {
      names: "",
      import_no: "",
      dialog: false,
      valid: true,
      query_type: 'name',
      totalItems: 0,
      items: [],
      select_org: { org_name: "", sid: "" },
      orgs: [],
      actual_individual_tags: [],
      tags: {},
      select_tags: [],
      individual_ids: '',
      loading: false,
      pagination: {},
      pageItems: [10, 20, 50],
      search: "",
      records: [],
      nextUrl: "",
      totalCount: 0,
      record_fields: [
        {
          value: "individual_no",
          text: "编号",
          sortable: true,
          breakpoints: [],
        },

        {
          value: "individual_name",
          text: "姓名",
          sortable: true,
          breakpoints: [],
        },
        {
          value: "individual_gender",
          text: "性别",
          sortable: true,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "individual_birthday",
          text: "出生日期",
          sortable: true,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "org_name",
          text: "机构名称",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "created_time",
          text: "建档日期",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
      ],
      export_data: [],
      export_item: {
        individual_no: "",
        individual_name: "",
        individual_gender: "",
        individual_birthday: "",
        org_name: "",
        created_time: ""
      },
    };
  },
  computed: {
    getHeaders() {
      let headers = [];
      for (let item of this.record_fields) {
        if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
          headers.push(item);
        }
      }
      return headers;
    }
  },
  watch: {
    pagination: {
      async handler() {
        const { page, itemsPerPage } = this.pagination;
        if (this.nextUrl && this.records.length < page * itemsPerPage) {
          this.loading = true;
          let url = this.nextUrl.split("?");
          try {
            let result = await api.post('individuals/get_individual_info/?' + url[url.length - 1], {
              names: this.names,
              org: this.select_org.sid,
              import_no: this.import_no,
              tags: this.select_tags,
              query_type: this.query_type
            });
            for (let item of result["results"]) {
              this.records.push(item);
            }
            this.individual_ids = ''
            for (let item of this.records) {
              this.individual_ids += item.individual_no + ','
            }
            if (this.individual_ids.length > 0) {
              this.individual_ids =  this.individual_ids.substring(0, this.individual_ids.length - 1)
            }
            this.nextUrl = result["next"];
            this.totalCount = result["count"];
            this.updateDataTable(this.records, this.totalCount);
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        } else {
          this.updateDataTable(this.records, this.totalCount);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.orgs = await api.get('organizations/get_export_org/?', null)
    this.tags = await api.get('tag_infos/get_export_tags/?', null)
  },
  methods: {
    updateDataTable(records, count) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let items = records;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy[0]];
          const sortB = b[sortBy[0]];

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }
      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      }
      this.items = items;
      this.totalItems = count;

    },
    async doSearch() {

      if (this.query_type === 'name' && this.names.length === 0) {
        nf.showErrorMsg("请输入客户名称");
        return;
      }
      // if (this.query_type === 'org' && this.select_org.sid === '') {
      //   nf.showErrorMsg("请选择一家机构");
      //   return;
      // }
      if (this.query_type === 'import_no' && this.import_no.length === 0) {
        nf.showErrorMsg("请输入导入编号");
        return;
      }
      if (this.query_type === 'org' && this.select_tags.length === 0) {
        nf.showErrorMsg("请至少选择一个产品");
        return;
      }
      this.loading = true;
      try {
        let result = await api.post("individuals/get_individual_info/?", {
          names: this.names,
          org: this.select_org.sid,
          import_no: this.import_no,
          tags: this.select_tags,
          query_type: this.query_type
        });
        this.loading = false;
        this.records = result["results"];
        this.individual_ids = ''
        for (let item of this.records) {
          this.individual_ids += item.individual_no + ','
        }
        if (this.individual_ids.length > 0) {
          this.individual_ids =  this.individual_ids.substring(0, this.individual_ids.length - 1)
        }
        this.nextUrl = result["next"];
        this.totalCount = result["count"];
        this.pagination.page = 1
        this.updateDataTable(this.records, this.totalCount);
      } catch (error) {
        this.loading = false;
      }
    },
    async doExport() {
      await api.post(
            "template_instances/record_data_export_log/?", {
              names: this.names,
              org: this.select_org.sid,
              import_no: this.import_no,
              tags: this.select_tags,
              query_type: this.query_type
            })
      let export_data = [];
      for (let item of this.records) {
        let export_item = {
          客户编号: "",
          姓名: "",
          性别: "",
          出生日期: "",
          机构名称: "",
          建档日期: "",
          民族: "",
          职业: "",
          学历: "",
          婚姻情况: "",
          籍贯: "",
          地址: ""
        };
        export_item["客户编号"] = item.individual_no;
        export_item["姓名"] = item.individual_name;
        export_item["性别"] = item.individual_gender === "MALE" ? "男" : "女";
        export_item["出生日期"] = item.individual_birthday;
        export_item["机构名称"] = item.organization.org_name;
        export_item["建档日期"] = item.created_time;
        export_item["民族"] = item.folk;
        export_item["职业"] = item.profession;
        export_item["学历"] = item.education;
        export_item["婚姻情况"] = item.marital_status;
        export_item["籍贯"] = item.native_place;
        export_item["地址"] = item.address;
        export_data.push(export_item);
      }
      const ws = XLSX.utils.json_to_sheet(export_data, {
        header: ["客户编号", "姓名", "性别", "出生日期", "机构名称"],
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "数据");
      /* generate file and send to client */
      XLSX.writeFile(wb, "customer_info.xlsx");
    },
  },
};
</script>
