var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"营养素编号/营养素名称","hide-details":"","clearable":""},on:{"click:clear":_vm.doClearSearch},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalItems,"loading":_vm.loading,"locale":"zh-CH","footer-props":{
              itemsPerPageText: '每页行数：',
              itemsPerPageOptions: _vm.pageItems,
            }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.medicine_no},[_c('td',[_c('router-link',{staticClass:"v-breadcrumbs__item",attrs:{"to":{
                        name: '营养素明细',
                        params: { id: item.sid },
                      }}},[_vm._v(" "+_vm._s(item.medicine_no)+" ")])],1),_c('td',[_vm._v(_vm._s(item.medicine_name))]),_c('td',[_vm._v(_vm._s(item.display_name))]),_c('td',[_vm._v(_vm._s(item.pharmaceutical_factory))])])}),0)]}}])},[_c('template',{slot:"no-data"},[_c('p',{staticClass:"text-xs-center grey--text"},[_vm._v("对不起，暂时无数据")])])],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }