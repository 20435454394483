import request from "@/utils/request";

declare module 'vue/types/vue' {
    interface Vue {
        [key: string]: any,
    }
}

import Vue from 'vue'
import vuetify from "./plugins/vuetify";
import App from './App.vue'
import './registerServiceWorker'
import router, {dynamic_menu, fixed_menu} from './router'
import store from './store'
import "./plugins/notifications";
import * as myFilters from "@/utils/filters";
import nf from "@/utils/notification";

Vue.config.productionTip = false;

Object.keys(myFilters).forEach(key => {
    Vue.filter(key, myFilters[key]);
});


router.beforeEach((to, from, next) => {
    if (!store.getters.token) {
        if (
            to.matched.length > 0 &&
            !to.matched.some(record => record.meta.requiresAuth)
        ) {
            next()
        } else {
            next({path: '/login'})
        }
    } else {
        if (store.getters.permissionList.length === 0) {
            request.get('users/get_menu_resources/').then(result => {
                for (let index in result) {
                    fixed_menu.children.push(dynamic_menu[result[index].path])
                }
                store.dispatch('setPermissionList', result).then(r=>{
                    router.addRoutes([fixed_menu])
                })
            }).catch(err => {
                store.dispatch("logout");
                router.replace("/login");
            })
        }

        if (to.path !== '/login') {
            next()
        } else {
            next(from.fullPath)
        }
    }
})

export default new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')


