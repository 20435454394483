<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card class="mx-3 my-3">
      <v-card-title class="pb-0">
        <v-spacer></v-spacer>
        <v-dialog v-model="showDialog" persistent width="450px">
          <template v-slot:activator="{ click }">
            <v-btn
              color="primary"
              class="mr-2"
              :disabled="!valid || dialog_save"
              :loading="dialog_save"
              v-on="click"
              @click.stop="doSubmit"
            >
              导入
            </v-btn>
          </template>
          <v-card v-if="showDialog">
            <v-card-title class="subtitle-2"
              ><span class="subtitle-2 orange--text text--darken-3"
                >数据文件解析失败</span
              ></v-card-title
            >
            <v-card-text>
              <v-simple-table fixed-header dense height="240px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>错误明细</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in valid_detail" :key="index">
                      <td>
                        {{ item }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="teal" text @click.native="showDialog = false"
                >关闭</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn color="grey" class="hidden-sm-and-down" @click="doBack">
          取消
        </v-btn>
      </v-card-title>
      <v-card-text >
        <v-row>
          <v-col md="6" sm="6" cols="12">
            <v-autocomplete
              v-model="select"
              :items="import_types"
              item-text="type_name"
              item-value="no"
              label="模版类型"
              persistent-hint
              return-object
              single-line
              :rules="rules.import_type"
              @change="doImportTypeChange"
            ></v-autocomplete>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <FileInputComponent
              @change="doChange"
              :rules="file_rules"
            />
          </v-col>
          <v-col cols="12">
            <v-simple-table fixed-header height="400px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="head in heads" :key="head">{{ head }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data" :key="index">
                    <td v-for="(value, index) in item" :key="index">
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
  
</template>
<script>
  import {api} from "@/utils/api";
  import nf from "@/utils/notification";
  import XS from "xlsx";
  import FileInputComponent from '@/components/common/FileInput.vue'

  const make_cols = ref_str =>
  Array(XS.utils.decode_range(ref_str).e.c + 1)
    .fill(0)
    .map((x, i) => ({ name: XS.utils.encode_col(i), key: i }));

export default {
  name: "NewDataImportRecord",
  components: { FileInputComponent },
  data() {
    return {
      valid: true,
      showDialog: false,
      dialog_save: false,
      valid_detail: "",
      files: [],
      organizations: [],
      doctors: [],
      import_types: [],
      is_need_org: false,
      select: { type_name: "", no: "", type: 1 },
      select_org: { org_name: "", sid: "" },
      select_doctor: { doctor_name: "", sid: "" },
      record: {
        import_type: "",
        source_org: "",
        health_consultant: "",
        raw_data: []
      },
      data: [],
      cols: [],
      heads: [],
      hint: "",
      rules: {
        import_type: [v => !!v.no || "不能为空"],
        source_org: [v => !!v.sid || "不能为空"],
        health_consultant: [v => !!v.sid || "不能为空"],
        raw_data: [v => !!v || "不能为空"]
      },
      file_rules: []
    };
  },
  methods: {
    doBack() {
      this.$router.replace({
        name: "记录列表",
        params: {}
      });
    },
    doChange(e) {
      if (e) {
        this.doReadFile(e);
      } else {
        this.data = [];
        this.cols = [];
        this.heads = [];
        this.hint = "";
        this.record.raw_data = "";
      }
    },
    async doImportTypeChange() {
      this.organizations = await api.get(
        "organizations/get_org_list/?t=" + this.select.no, null
      );
      // this.is_need_org = this.select.type === 1
      // this.select_org.sid = this.select_org.org_name = ''
      // this.doctors = []
      // this.select_doctor.sid = this.select_doctor.doctor_name = ''
    },
    async doOrgChange() {
      this.doctors = await api.get(
        "doctors/get_health_consultant_list/?o=" + this.select_org.sid, null
      );
      this.select_doctor.sid = this.select_doctor.doctor_name = ''
    },
    doReadFile(file) {
      const reader = new FileReader();
      reader.onload = e => {
        /* Parse data */
        const b_str = e.target.result;
        const wb = XS.read(b_str, {
          type: "binary",
          cellText: false,
          cellDates: true
        });
        /* Get first worksheet */
        const ws_name = wb.SheetNames[0];
        const ws = wb.Sheets[ws_name];
        /* Convert array of arrays */
        const data = XS.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
          dateNF: "yyyy/mm/dd"
        });
        /* Update state */
        this.data = data.slice(1, data.length);
        this.heads = data[0];
        this.cols = make_cols(ws["!ref"]);
        this.hint = "记录数为 " + this.data.length + " 条";
        this.record.raw_data = data;
      };
      reader.readAsBinaryString(file);
    },
    async doSubmit() {
      if (this.data.length === 0) {
        nf.showErrorMsg("数据不能为空");
        return;
      }
      if (this.$refs.form.validate()) {
        this.dialog_save = true;
        try {
          this.record.import_type = this.select.no;
          this.record.source_org = this.select_org.sid;
          this.record.health_consultant = this.select_doctor.sid;
          let result = await api.post(
            "operation_import_data_record/?",
            this.record
          );
          if (result.code !== 200) {
            this.valid_detail = result.result;
            this.showDialog = true;
            this.dialog_save = false;
            return;
          }
          nf.showSuccessMsg("数据保存成功！");
          await this.$router.replace({
            name: "记录列表",
            params: {}
          });
        } catch (error) {
          nf.showErrorMsg(error);
          this.dialog_save = false;
        }
      }
    }
  },
  async mounted() {
    this.import_types = await api.get("operation_import_data_record/import_types/?", null);
  }
};
</script>
