import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import colors from "vuetify/lib/util/colors";
import {UserVuetifyPreset} from "vuetify/types/services/presets";

const opts: Partial<UserVuetifyPreset> = {
  theme: {
    themes: {
      light: {
        primary: colors.deepPurple,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.blue.darken3,
        warning: colors.amber.darken4,
        error: colors.deepOrange.accent4,
        success: colors.green.accent4,
      },
      dark: {
        primary: colors.deepPurple,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.blue.darken3,
        warning: colors.amber.darken4,
        error: colors.deepOrange.accent4,
        success: colors.green.accent4,
      }
    }
  }
};

Vue.use(Vuetify);

export default new Vuetify(opts);
