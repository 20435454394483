<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-0">
        <router-link
          v-bind:to="{ name: '新增记录', params: {} }"
          class="v-breadcrumbs__item"
        >
          <v-btn absolute dark fab top left color="teal">
            <v-icon>add</v-icon>
          </v-btn>
        </router-link>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="11">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="编号/名称"
              hide-details
              @keyup.enter.native="doSearch"
              clearable
              @click:clear="doSearchClear"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-data-table
              :headers="getHeaders"
              :items="items"
              :options.sync="pagination"
              :server-items-length="totalItems"
              :loading="loading"
              locale="zh-CH"
              :footer-props="{
                itemsPerPageText: '每页行数：',
                itemsPerPageOptions: pageItems
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.individual_no">
                    <td>
                      <router-link
                        v-bind:to="{ name: '查看导入记录', params: { id: item.sid }}"
                        class="v-breadcrumbs__item"
                      >
                        <!-- <router-link v-bind:to="'/customer/detail/'+item.sid" class="v-breadcrumbs__item"> -->
                        {{ item.import_no }}
                      </router-link>
                    </td>
                    <td>{{ item.import_name }}</td>
                    <td class="hidden-sm-and-down">
                      {{ item.created_time | formatTimeLong}}
                    </td>
                    <td>{{ item.creator_name }}</td>
                    <td>{{ item.record_count }}</td>
                    <td>{{ item.org_nos }}</td>
                  </tr>
                </tbody>
              </template>

              <template slot="no-data">
                <p class="text-xs-center grey--text">对不起，暂时无数据</p>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import {api} from "@/utils/api";
  import {has_perm} from "@/utils/auth";

  export default {
  name: "DataImportRecord",
  data() {
    return {
      dialog: false,
      valid: true,
      totalItems: 0,
      items: [],
      org: {},
      actual_individual_tags: [],
      tags: {},
      loading: false,
      pagination: {},
      pageItems: [10, 20, 50],
      search: "",
      records: [],
      nextUrl: "",
      totalCount: 0,
      record_fields: [
        {
          value: "import_no",
          text: "编号",
          sortable: true,
          breakpoints: []
        },

        {
          value: "import_name",
          text: "名称",
          sortable: true,
          breakpoints: []
        },
        // {
        //   value: "import_status",
        //   text: "状态",
        //   sortable: true,
        //   breakpoints: ["xs", "sm"]
        // },
        {
          value: "created_time",
          text: "操作日期",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "creator_name",
          text: "操作人",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "record_counr",
          text: "记录数",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "org_nos",
          text: "机构编码",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
      ],
      radios: 0
    };
  },

  computed: {
    getHeaders() {
      let headers = [];
      //console.log(this.$vuetify.breakpoint.name)
      for (let item of this.record_fields) {
        if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
          headers.push(item);
        }
      }
      return headers;
    }
  },
  watch: {
    pagination: {
      async handler() {
        const { page, itemsPerPage } = this.pagination;
        if (this.nextUrl && this.records.length < page * itemsPerPage) {
          this.loading = true;
          let url = this.nextUrl.split("?");
          try {
            let result = await api.get(
              "operation_import_data_record/?" + url[url.length - 1],
              this.search
            );
            for (let item of data["results"]) {
              this.records.push(item);
            }
            this.nextUrl = data["next"];
            this.totalCount = data["count"];
            this.updateDataTable(this.records, this.totalCount);
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        } else {
          this.updateDataTable(this.records, this.totalCount);
        }
      },
      deep: true
    }
  },
  mounted() {
     this.doLoadData();
  },
  methods: {
    async doLoadData() {
      this.loading = true;
      try {
        let result = await api.get("operation_import_data_record/");
        this.loading = false;
        this.records = result["results"];
        this.nextUrl = result["next"];
        this.totalCount = result["count"];
        this.updateDataTable(this.records, this.totalCount);
      } catch (error) {
        this.loading = false;
      }
    },
    updateDataTable(records, count) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let items = records;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy[0]];
          const sortB = b[sortBy[0]];

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }
      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      }
      this.items = items;
      this.totalItems = count;
    },

    async doSearch() {
      this.loading = true;
      try {
        let result = await api.get(
          "operation_import_data_record/?search=" +
            this.search
        );
        this.loading = false;
        this.records = result["results"];
        this.nextUrl = result["next"];
        this.totalCount = result["count"];
        this.updateDataTable(this.records, this.totalCount);
      } catch (error) {
        this.loading = false;
      }
    },
    doSearchClear() {
      this.search = "";
      this.doSearch();
    },
    doSetParams(value) {
      let params = this.$store.getters.getParams;
      params.id = value;
      this.$store.dispatch("setParams", params);
      this.$store.dispatch("clearTemplateFilters");
    }
  }
};
</script>

<style scoped></style>
