<template>
	<v-container fluid>
		<v-tabs>
			<v-tab key="customer_info">数据转换</v-tab>
			<v-tab-item key="customer_info">
				<DC></DC>
			</v-tab-item>
		</v-tabs>
	</v-container>
</template>

<script>
  import DC from "./dc";

  export default {
    name: "Tools",
    components: { DC },
    data() {
      return {
      }
    },
    methods: {
    }
  };
</script>
