import { ActionContext } from 'vuex'

export interface User {
  last_name: string,
  first_name: string,
  username: string,
  id: string,
  groups: [{}],
  user_permissions: [{}]
}


export interface State {
  token: '',
  refresh: '',
  user: User
}
const state: State = {
  token: '',
  refresh: '',
  user: {
    last_name: '',
    first_name: '',
    username: '',
    id: '',
    groups: [{}], user_permissions: [{}]
  }
}

const mutations = {
  setUserInfo(state: State, result: any) {
    state.user = result.user;
    state.token = result.access;
    state.refresh = result.refresh;
  },
  clearUserInfo(state: State) {
    state.token = ''
    state.refresh = ''
  }
}

const getters = {
  realName: (state: State) => state.user.last_name + state.user.first_name,
  loggedIn: (state: State) => !!state.token,
  token: (state: State) => state.token,
  userName: (state: State) => state.user.username,
  userId: (state: State) => state.user.id,
  userInfo: (state: State) => state.user
}

const actions = {
  setUserInfo(context: ActionContext<State, any>, result: any) {
    context.commit('setUserInfo', result)
  },
  logout(context: ActionContext<State, any>) {
    context.commit('clearUserInfo')
  }
}

const sessionUser = {
  state,
  mutations,
  getters,
  actions
}
export default sessionUser;

