<template>
  <div class="download-board">
    <h3 class="text-center">全部任务</h3>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>下载内容</th>
            <th class="text-center">文件名称</th>
            <th class="text-center">大小</th>
            <th class="text-center">状态</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in downloadTask"
            :key="item.url"
            class="progress-contain"
          >
            <td>{{ item.name }}</td>
            <td class="text-center">{{ item.filename }}</td>
            <td class="text-center">{{ formatSize(item.total) }}</td>
            <td class="text-center">
              <div class="green--text" v-if="item.status == 1">
                下载成功
                <div>耗时{{timeConsum(item.startDate, item.endDate)}}</div>
              </div>
              <div class="red--text" v-if="item.status == 0">
                下载失败
                <div>{{item.statusDesc}}</div>
              </div>
            </td>
            <td class="text-center">
              <div v-if="item.status == -1">
                <a @click="() => stop(item)">停止下载</a>
              </div>
              <div v-else>
                <a @click="() => restart(item)">重新下载</a>
                <div><a @click="() => deleteTask(item)">删除</a></div>
              </div>
            </td>
            <div class="progress" :style="{ width: `${item.percentage}%` }"></div>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { api } from "@/utils/api";
export default {
  name: 'DownloadBoard',
  data() {
    return {}
  },
  computed: {
    downloadTask() {
      const dataMap = this.$store.state.downloadTaskMap
      const data = []
      Object.keys(dataMap).map(url => {
        data.push({ url, ...dataMap[url] })
      })
      return data
    }
  },
  methods: {
    restart(item) {
      api.download(item)
    },
    stop(item) {
      this.$store.commit('downloadFail', { url: item.url, desc: '用户取消' })
      item.cancel()
    },
    deleteTask(item) {
      this.$store.commit('deleteDownloadTask', item.url)
    },
    formatSize(size = 0) {
      return size > 1024
        ? size / 1024 > 1024
          ? size / (1024 * 1024) > 1024
            ? (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
            : (size / (1024 * 1024)).toFixed(2) + 'MB'
          : (size / 1024).toFixed(2) + 'KB'
        : (size).toFixed(2) + 'B'
    },
    timeConsum(start, end) {
      const millisecond = Date.parse(end || new Date()) - Date.parse(start)
      let time = Math.round(millisecond / 1000) || 1
      if (time < 60) {
        return `${time}秒`
      }
      time = Math.round(time / 60)
      if (time < 60) {
        return `${time}分钟`
      }
      time = Math.round(time / 60)
      if (time < 60) {
        return `${time}小时`
      }
      time = Math.round(time / 24)
      return `${time}天`
    }
  }
}
</script>
<style scoped>
.download-board {
  background: #ffffff;
  padding: 16px;
  max-height: 600px;
  overflow: auto;
}
.progress-contain {
  position: relative;
}
.progress-contain td {
  position: relative;
  z-index: 2;
}
.progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #aad3f7;
  z-index: 1;
  transition: width .3s;
}
</style>