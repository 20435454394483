var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"primary","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("客")])]),_c('v-label',{staticClass:"caption"},[_vm._v("客户批量导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于批量客户建档，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。 ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/客户建档.xlsx","download":"客户建档.xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"indigo","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("筛")])]),_c('v-label',{staticClass:"caption"},[_vm._v("心脑风险筛查体检数据导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<心脑风险筛查>体检数据的批量导入，模版红色文字单元格表示必填项，选择题选项的对应值参照模版中的说明页，日期的格式为YYYY/MM/DD。 ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/4HEX01-心脑风险筛查查体信息-含客户信息.xlsx","download":"心脑风险筛查查体信息-含客户信息.xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("叶")])]),_c('v-label',{staticClass:"caption"},[_vm._v("叶酸利用能力检测数据导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<叶酸利用能力>检测数据的批量导入，模版红色文字单元格表示必填项，选择题选项的对应值参照模版中的说明页，日期的格式为YYYY/MM/DD。 ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/FA-叶酸利用能力检测.xlsx","download":"叶酸利用能力检测-含客户信息.xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("VD")])]),_c('v-label',{staticClass:"caption"},[_vm._v("维生素D检测数据导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<维生素D检测>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/VD-维生素D检测(3项).xlsx","download":"维生素D检测(3项)-含客户信息.xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("营")])]),_c('v-label',{staticClass:"caption"},[_vm._v("营养与毒性元素(血液14项)导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<营养与毒性元素(血液14项)>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/NT-营养与毒性元素(血液14项).xlsx","download":"营养与毒性元素(血液14项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("营")])]),_c('v-label',{staticClass:"caption"},[_vm._v("营养与毒性元素(头发39项)导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<营养与毒性元素(头发39项)>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/NT-营养与毒性元素(头发39项).xlsx","download":"营养与毒性元素(头发39项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("营")])]),_c('v-label',{staticClass:"caption"},[_vm._v("营养与毒性元素(血液20项)导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<营养与毒性元素(血液20项)>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/NT-营养与毒性元素(血液20项).xlsx","download":"营养与毒性元素(血液20项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("糖")])]),_c('v-label',{staticClass:"caption"},[_vm._v("糖化终产物导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<糖化终产物>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/AGEs-糖化终产物(1项).xlsx","download":"糖化终产物(1项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("血")])]),_c('v-label',{staticClass:"caption"},[_vm._v("血管内皮检测(9项)导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<血管内皮检测(9项)>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/VE-血管内皮检测(9项).xlsx","download":"血管内皮检测(9项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("辅")])]),_c('v-label',{staticClass:"caption"},[_vm._v("辅酶Q10导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<辅酶Q10>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/Q10-辅酶Q10检测(1项).xlsx","download":"辅酶Q10检测(1项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("辅")])]),_c('v-label',{staticClass:"caption"},[_vm._v("8-羟基脱氧鸟苷导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<8-羟基脱氧鸟苷>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/8-羟基脱氧鸟苷检测(1项).xlsx","download":"8-羟基脱氧鸟苷检测(1项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("脂")])]),_c('v-label',{staticClass:"caption"},[_vm._v("脂肪酸分析血清11项导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<脂肪酸分析11项>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/脂肪酸-血清(11项).xlsx","download":"脂肪酸-血清(11项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("脂")])]),_c('v-label',{staticClass:"caption"},[_vm._v("脂肪酸分析干血片5项导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<脂肪酸分析干血片5项>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/脂肪酸-干血片(5项).xlsx","download":"脂肪酸-干血片(5项).xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","sm":"6","cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover? 8 : 2}},[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"teal","size":"24"}},[_c('span',{staticClass:"white--text caption"},[_vm._v("VB")])]),_c('v-label',{staticClass:"caption"},[_vm._v("心脑风险筛查VB版数据导入模版")])],1),_c('v-card-text',[_c('v-label',{staticClass:"caption"},[_vm._v("用于<心脑风险筛查VB版>和<心脑风险筛查VB加强版>检测数据的批量导入，模版红色文字单元格表示必填项，日期的格式为YYYY/MM/DD。")])],1),_c('v-card-actions',[_c('v-spacer'),_c('a',{attrs:{"href":"./static/templates/心脑风险筛查VB版.xlsx","download":"心脑风险筛查VB版.xlsx"}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("下载")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }