<template>
  <v-card class="mx-3 my-3">
    <v-card-title class="pb-0">
      <v-radio-group
        v-model="report_status"
        row
        @change="items = selected = []"
      >
        <v-radio label="已发布" value="RP"></v-radio>
        <v-radio label="未发布" value="INIT"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-radio-group v-model="query_type" row @change="items = selected = []">
        <v-radio label="按报告模版" value="3"></v-radio>
        <v-radio label="按客户编号" value="1"></v-radio>
        <v-radio label="按导入编号" value="2"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-radio-group v-model="phone_status" row>
        <v-radio label="有手机号" value="1"></v-radio>
        <v-radio label="无手机号" value="2"></v-radio>
        <v-radio label="全部" value="3"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mr-2"
        :loading="loading"
        @click.stop="doSearch"
      >
        查询</v-btn
      >
      <v-dialog v-model="dialog" persistent max-width="400px">
        <template v-slot:activator="{ on }">
          <v-btn
            color="teal"
            class="mr-2"
            :disabled="selected.length === 0"
            :loading="loading"
            v-on="on"
          >
            {{ report_status == "RP" ? "撤销发布" : "发布" }}</v-btn
          >
        </template>
        <v-form
          ref="formReport"
          v-model="valid"
          lazy-validation
          v-on:submit.prevent="onSubmit"
        >
          <v-card>
            <v-card-title>
              <v-label v-if="report_status === 'RP'">撤销发布报告</v-label>
              <v-label v-else>发布报告</v-label>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-label v-if="report_status === 'RP'"
                    >您确认撤销发布报告吗？一旦撤销，C端客户将无法通过生命云图小程序看到自己的报告</v-label
                  >
                  <v-label v-else
                    >您确认发布报告吗？一旦发布报告，C端客户通过生命云图小程序即可看到自己的报告</v-label
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="doSubmit">{{
                report_status == "RP" ? "确认撤销" : "确认发布"
              }}</v-btn>
              <v-btn color="blue darken-1" text @click="dialog = false"
                >取消</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="4" sm="6" cols="12" class="py-0" v-if="query_type==='3'">
          <v-autocomplete
            v-model="select"
            :items="export_reports"
            item-text="report_name"
            item-value="no"
            label="报告模版"
            persistent-hint
            return-object
            single-line
            @change="doReportChange"
          ></v-autocomplete>
        </v-col>
        <v-col md="4" sm="6" cols="12" class="py-0" v-if="query_type==='3'">
          <v-autocomplete
            v-model="select_org"
            :items="orgs"
            item-text="org_name"
            item-value="sid"
            label="机构"
            persistent-hint
            return-object
            single-line
            @change="items = selected = []"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0" v-if="query_type!=='3'">
          <v-text-field
            v-model="search"
            hint="多个编号之间请用逗号','分隔"
            persistent-hint
            label="客户编号/导入编号"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :items="items"
            :loading="loading"
            locale="zh-CH"
            item-key="sid"
            show-select
            :footer-props="{
              itemsPerPageText: '每页行数：',
              itemsPerPageOptions: pageItems,
            }"
          >
            <template v-slot:item.individual_gender="{ item }">
              <v-label>{{
                item.individual_gender === "MALE" ? "男" : "女"
              }}</v-label>
            </template>
            <template v-slot:item.external_status="{ item }">
              <v-label>{{
                item.external_status === "RP" ? "已发布" : "未发布"
              }}</v-label>
            </template>
            <template slot="no-data">
              <p class="text-xs-center grey--text">对不起，暂时无数据</p>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";

export default {
  name: "IndividualRecord",
  data() {
    return {
      export_reports: [],
      select: { report_name: "", no: "", type: "" },
      orgs: [],
      select_org: { org_name: "", sid: "" },
      report_status: "INIT",
      query_type: "1",
      phone_status: "1",
      search: "",
      loading: false,
      items: [],
      records: [],
      totalItems: 0,
      totalCount: 0,
      nextUrl: "",
      pagination: {},
      pageItems: [50, 100, 200],
      selected: [],
      expanded: [],
      headers: [
        {
          value: "instance_name",
          text: "报告名称",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "report_date",
          text: "报告日期",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "individual.individual_no",
          text: "客户编号",
          sortable: false,
          breakpoints: [],
        },
        {
          value: "individual.individual_name",
          text: "姓名",
          sortable: false,
          breakpoints: [],
        },
        {
          value: "individual.organization.org_name",
          text: "机构名称",
          sortable: false,
          breakpoints: [],
        },
        {
          value: "individual.health_consultant.doctor_name",
          text: "健康管理师",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },

        {
          value: "external_status",
          text: "状态",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
      ],
      dialog: false,
      valid: true,
      rules: {
        report_date: [
          (v) => !!v || "不能为空，格式YYYY-MM-DD",
          (v) =>
            /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(
              v
            ) || "日期格式错误",
        ],
        sample_type: [(v) => !!v || "不能为空"],
        sample_no: [(v) => !!v || "不能为空"]
      },
      report_date: "",
      sample_type: "",
      post_data: {
        update_type: "",
        details: [],
      },
    };
  },
  watch: {
    pagination: {
      async handler() {
        const { page, itemsPerPage } = this.pagination;
        if (this.nextUrl && this.records.length < page * itemsPerPage) {
          this.loading = true;
          let url = this.nextUrl.split("?");
          try {
            let data = await api.get(
              "report_instances/get_report_instances/?" + url[url.length - 1],
              this.search
            );
            for (let item of data["results"]) {
              this.records.push(item);
            }
            this.nextUrl = data["next"];
            this.totalCount = data["count"];
            this.updateDataTable(this.records, this.totalCount);
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        } else {
          this.updateDataTable(this.records, this.totalCount);
        }
      },
      deep: true,
    },
  },
  methods: {
    updateDataTable(records, count) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let items = records;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy[0]];
          const sortB = b[sortBy[0]];

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }
      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      }
      this.items = items;
      this.totalItems = count;
    },
    async doReportChange() {
      this.orgs = await api.get(
        "organizations/get_org_list_by_report/?r=" + this.select.no,
        null
      );
      this.select_org.sid = this.select_org.org_name = "";
      this.records = this.selected = [];
      this.items= [];
      // this.doSearch()
    },
    async doSearch() {
      this.loading = true;
      if (this.query_type === "3") {
        if (this.select.no === "") {
        nf.showErrorMsg("请选择报告模版");
        this.loading = false;
        return;
        }
        if (this.select_org.sid === "") {
          nf.showErrorMsg("请选择机构");
          this.loading = false;
          return;
        }
      } else {
        if (this.search === "") {
        nf.showErrorMsg("请输入客户编号或导入编号");
        this.loading = false;
        return;
        }
      }
      
      let search_str = "";
      if (this.search) {
        search_str = this.search;
      } else {
        search_str = "";
      }
      try {
        const result = await api.get(
          "report_instances/get_report_instances/?" +
            "r=" +
            this.select.no +
            "&o=" +
            this.select_org.sid +
            "&s=" +
            this.report_status +
             "&t=" +
            this.query_type +
            "&p=" +
            this.phone_status +
            "&nos=" +
            search_str,
          null
        );
        this.loading = false;
        this.records = result["results"];
        this.nextUrl = result["next"];
        this.totalCount = result["count"];
        this.pagination.page = 1
        this.updateDataTable(this.records, this.totalCount);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async doSubmit() {
      if (this.$refs.formReport.validate()) {
        this.post_data.update_type = this.report_status;
        this.post_data.details = this.selected;
        

        await api.post("report_instances/report_update/?", this.post_data);
        nf.showSuccessMsg("报告操作完毕");
        await this.doSearch();
        this.dialog = false;
      }
    },
  },
  async mounted() {
    this.export_reports = await api.get(
      "report_templates/get_export_report_template/?",
      null
    );
  },
};
</script>
