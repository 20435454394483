<template>
  <v-card class="mx-3 my-3">
    <v-card-title>
      <v-label>{{ record.individual_name }}--{{ record.individual_no }}</v-label>
      <v-spacer></v-spacer>
      <v-btn color="grey" @click="doBack">
        返回
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="4" sm="6" cols="12"
          ><v-label
            >操作类型：{{ record.action }}</v-label
          ></v-col
        >
        <v-col md="4" sm="6" cols="12"
          ><v-label
            >操作时间：{{ record.created_time | formatTimeLong }}</v-label
          ></v-col
        >
        <v-col md="4" sm="6" cols="12"
          ><v-label>操作者：{{ record.created_by.first_name }}</v-label></v-col
        >
        <v-col cols="12" class="pb-0">
          <v-label>详细信息：</v-label>
        </v-col>
        <v-col cols="12">
          <v-label>{{ record.action_desc }}</v-label>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";

export default {
  name: "ViewLog",
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      record: {
        individual:{},
        created_by:{}
      }
    };
  },
  methods: {
    doBack() {
      this.$router.replace({
        name: "日志查询",
        params: {}
      });
    },
  },

  async mounted() {
    this.record = await api.get(
      "import_logs/" + this.$route.params.id + "/?"
    );
  }
};
</script>
