var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"客户编号","hide-details":"","clearable":""},on:{"click:clear":_vm.doClearSearch},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalItems,"loading":_vm.loading,"locale":"zh-CH","footer-props":{
                itemsPerPageText: '每页行数：',
                itemsPerPageOptions: _vm.pageItems,
              }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.individual_no",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticClass:"v-breadcrumbs__item",attrs:{"to":{ name: '查看日志信息', params: { id: item.sid } }}},[_vm._v(" "+_vm._s(item.individual_no)+" ")])]}},{key:"item.created_time",fn:function(ref){
              var item = ref.item;
return [_c('v-label',[_vm._v(_vm._s(_vm._f("formatTimeLong")(item.created_time)))])]}},{key:"item.created_by",fn:function(ref){
              var item = ref.item;
return [_c('v-label',[_vm._v(_vm._s(item.created_by.first_name))])]}}])},[_c('template',{slot:"no-data"},[_c('p',{staticClass:"text-xs-center grey--text"},[_vm._v("对不起，暂时无数据")])])],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }