<template>
    <v-card>
        <v-card-title class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    class="mr-2"
                    :disabled="!chooseFile || !org"
                    :loading="loading"
                    @click="handleSubmit"
            >
                开始转换
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col md="6" sm="6" cols="12">
                    <v-autocomplete
                            v-model="org"
                            :items="[
              { text: '协和江南', value: 'xiehejiangnan' },
              { text: '安吉联生', value: 'liansheng' },
              { text: '辽电沈阳局', value: 'liaodian' },
              { text: '辽宁电力中心医院', value: 'liaodianvbr'}
            ]"
                            item-text="text"
                            item-value="value"
                            label="选择机构"
                            persistent-hint
                            return-object
                            single-line
                    ></v-autocomplete>
                </v-col>
                <v-col md="6" sm="6" cols="12" v-if="org.value==='xiehejiangnan'">
                    <v-file-input
                        accept=".zip"
                        label="请选择压缩文件"
                        persistent-hint
                        @change="handleFileChange"
                    ></v-file-input>
                </v-col>
                <v-col md="6" sm="6" cols="12" v-else>
                    <FileInputComponent
                        @change="handleFileChange"
                    />
                </v-col>
                <v-col cols="12">
                    <v-simple-table fixed-header height="400px">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th style="word-break: keep-all;white-space: nowrap;" v-for="head in heads" :key="head">
                                    {{ head }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in data" :key="index">
                                <td style="word-break: keep-all;white-space: nowrap;" v-for="(value, index) in item"
                                    :key="index">
                                    {{ value }}
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {api} from "@/utils/api"
import {excel2Json} from "@/utils/xs_api"
import FileInputComponent from '@/components/common/FileInput.vue'

export default {
    name: "Tools-DC",
    components: {FileInputComponent},
    data() {
        return {
            loading: false,
            chooseFile: false,
            org: '',
            fileName: '',
            data: [],
            cols: [],
            heads: [],
            record: {
                import_type: "",
                source_org: "",
                health_consultant: "",
                raw_data: []
            },
            accept_file: {
                type: String,
                default: '.xlsx, .xls'
            },
            file: null
        }
    },
    methods: {

        handleFileChange(e) {
            this.chooseFile = !!e
            if (e) {
                this.fileName = e.name.split('.').shift()
                this.doReadFile(e);
            } else {
                this.data = [];
                this.cols = [];
                this.heads = [];
                this.hint = "";
                this.record.raw_data = "";
            }
        },
        async doReadFile(file) {
            if (this.org.value !== "xiehejiangnan") {
                const {data, cols} = await excel2Json(file)
                this.data = data.slice(1, data.length);
                this.heads = data[0];
                this.cols = cols;
                this.hint = "记录数为 " + this.data.length + " 条";
                this.record.raw_data = data
                this.file = null
            } else {
                this.data = [];
                this.cols = [];
                this.heads = [];
                this.hint = "";
                this.record.raw_data = "";
                this.file = file
            }
        },
        async handleSubmit() {
            this.loading = true
            try {
                const fd = new FormData()
                fd.append('file', this.file)
                fd.append('org', this.org.value)
                fd.append('filename', this.fileName)
                fd.append('heads', this.heads)
                fd.append('data', this.data)
                await api.download({
                    name: this.fileName,
                    method: 'post',
                    url: `/api/tools/dc/?f=${encodeURIComponent(this.fileName)}`,
                    data: fd
                })
            } catch (e) {
                console.error(e)
            }
            this.loading = false
        }
    }
};
</script>
