<template>
  <v-card class="mx-3 my-3">
    <v-card-title>
      <v-label>{{ record.medicine_name }}--{{ record.medicine_no }}</v-label>
      <v-spacer></v-spacer>
      <v-btn color="grey" @click="doBack">
        返回
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12"
          ><v-label>规格：{{ record.spec }}</v-label></v-col
        >
        <v-col cols="12"
          ><v-label>服用剂量：{{ record.dosage }}</v-label></v-col
        >
        <v-col cols="12" class="pb-0">
          <v-label>成份：{{ record.ingredient }}</v-label>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-label>功效：{{ record.efficacy }}</v-label>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-label>注意事项：{{ record.prompt }}</v-label>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";

export default {
  name: "ViewMedicine",
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      record: {
        medicine_no: '',
        medicine_name: '',
        display_name: '',
        pharmaceutical_factory: '',
        spec: '',
        dosage: '',
        ingredient: '',
        efficacy: '',
        prompt: ''
      }
    };
  },
  methods: {
    doBack() {
      this.$router.replace({
        name: "营养素查询",
        params: {}
      });
    },
  },

  async mounted() {
    this.record = await api.get(
      "medicines/" + this.$route.params.id + "/?"
    );
  }
};
</script>
