<template>
	<v-container fluid>
		<v-card>
			<v-card-title class="pb-0">
				<v-row>
					<v-col cols="1"></v-col>
					<v-col cols="11">
						<v-text-field
							v-model="search"
							append-icon="search"
							label="客户编号"
							hide-details
							@keyup.enter.native="doSearch"
							clearable
							@click:clear="doClearSearch"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						<v-data-table
							:headers="getHeaders"
							:items="items"
							:options.sync="pagination"
							:server-items-length="totalItems"
							:loading="loading"
							locale="zh-CH"
							:footer-props="{
                itemsPerPageText: '每页行数：',
                itemsPerPageOptions: pageItems,
              }"
						>
							<template v-slot:item.individual_no="{ item }">
								<router-link
									v-bind:to="{ name: '查看日志信息', params: { id: item.sid } }"
									class="v-breadcrumbs__item"
								>
									<!-- <router-link v-bind:to="'/customer/detail/'+item.sid" class="v-breadcrumbs__item"> -->
									{{ item.individual_no }}
								</router-link>
							</template>
							<template v-slot:item.created_time="{ item }">
								<v-label>{{ item.created_time | formatTimeLong }}</v-label>
							</template>
							<template v-slot:item.created_by="{ item }">
								<v-label>{{ item.created_by.first_name }}</v-label>
							</template>
							<template slot="no-data">
								<p class="text-xs-center grey--text">对不起，暂时无数据</p>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
  import {api} from "@/utils/api";
  import nf from "@/utils/notification";

  export default {
    name: "LogRecord",
    data() {
      return {
        dialog: false,
        dialog_delete: false,
        valid: true,
        totalItems: 0,
        items: [],
        org: {},
        actual_individual_tags: [],
        tags: {},
        loading: false,
        pagination: {},
        pageItems: [10, 20, 50],
        search: this.$store.getters.logSearch,
        records: [],
        nextUrl: "",
        totalCount: 0,
        record_fields: [
          {
            value: "individual_no",
            text: "编号",
            sortable: false,
            breakpoints: [],
          },

          {
            value: "individual_name",
            text: "姓名",
            sortable: false,
            breakpoints: [],
          },
          {
            value: "action",
            text: "操作类型",
            sortable: false,
            breakpoints: ["xs", "sm"],
          },
          {
            value: "created_time",
            text: "操作时间",
            sortable: false,
            breakpoints: ["xs", "sm"],
          },
          {
            value: "created_by",
            text: "操作者",
            sortable: false,
            breakpoints: ["xs", "sm"],
          },
        ],
        radios: 0,
      };
    },
    computed: {
      getHeaders() {
        let headers = [];
        for (let item of this.record_fields) {
          if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
            headers.push(item);
          }
        }
        return headers;
      },
      logSearch: {
        get() {
          return this.$store.getters.logSearch
        },
        set(value) {
          this.$store.dispatch('setLogSearch', value)
        }
      }
    },
    watch: {
      pagination: {
        async handler() {
          const {page, itemsPerPage} = this.pagination;
          if (this.nextUrl && this.records.length < page * itemsPerPage) {
            this.loading = true;
            let url = this.nextUrl.split("?");
            try {
              let result = await api.get(
                "import_logs/?" + url[url.length - 1],
                this.search
              );
              for (let item of data["results"]) {
                this.records.push(item);
              }
              this.nextUrl = data["next"];
              this.totalCount = data["count"];
              this.updateDataTable(this.records, this.totalCount);
              this.loading = false;
            } catch (error) {
              this.loading = false;
            }
          } else {
            this.updateDataTable(this.records, this.totalCount);
          }
        },
        deep: true,
      },
    },
    async mounted() {
      if (this.logSearch.length > 0) {
        try {
          let result = await api.get("import_logs/?search=" + this.logSearch);
          this.loading = false;
          this.records = result["results"];
          this.nextUrl = result["next"];
          this.totalCount = result["count"];
          this.updateDataTable(this.records, this.totalCount);
        } catch (error) {
          this.loading = false;
        }
      }
    },
    methods: {
      updateDataTable(records, count) {
        const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;
        let items = records;

        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }
        if (itemsPerPage > 0) {
          items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }
        this.items = items;
        this.totalItems = count;
      },

      async doSearch() {
        this.loading = true;
        if (this.search == null || this.search.length == 0) {
          nf.showErrorMsg("请输入查询条件");
          this.loading = false;
          return;
        }
        try {
          let result = await api.get("import_logs/?search=" + this.search);
          this.loading = false;
          this.records = result["results"];
          this.nextUrl = result["next"];
          this.totalCount = result["count"];
          this.$store.dispatch('setLogSearch', this.search)
          this.updateDataTable(this.records, this.totalCount);
        } catch (error) {
          this.loading = false;
        }
      },
      doClearSearch() {
        this.$store.dispatch('clearLogSearch')
        this.items = []
      }
    },
  };
</script>
