import { formatDate } from './date'
import store from '../store'
import { getFirstWord, getLastWord, getShortWord } from './strings'

// 格式化日期为 yyyy-MM-dd
export function formatDateDash(value: string): any {
  let date = new Date(value)
  return formatDate(date, 'yyyy-MM-dd')
}

// 格式化日期为 yyyy年MM月dd日
export function formatDateLong(value: string): any  {
  let date = new Date(value);
  return formatDate(date, "yyyy年MM月dd日");
}

// 格式化日期为 MM-dd HH:mm
export function formatTimeShort(value: string): any  {
  let date = new Date(value);
  return formatDate(date, "MM-dd hh:mm");
}

// 格式化日期为 MM-dd HH:mm
export function formatTimeLong(value: string): any {
  let date = new Date(value);
  return formatDate(date, "yyyy-MM-dd hh:mm");
}

// 获得字符串第一个字符
export function getFirst(value: string): any  {
  return getFirstWord(value, 1);
}

// 获得字符串的省略形式
export function getShortWordComments(value: string): any  {
  return getShortWord(value, 4);
}

// 获得字符串的省略形式
export function getLongWordComments(value: string): any  {
  return getShortWord(value, 8);
}

// 获得字符串最后8个长度的字符
export function getLastEight(value: string): any  {
  return getLastWord(value, 8);
}

// 获得字符串最后7个长度的字符
export function getLastSeven(value: string): any  {
  return getLastWord(value, 7);
}

// 获得字符串最后12个长度的字符
export function getLastTwelve(value: string): any  {
  return getLastWord(value, 12);
}

// 获得字符串最后12个长度的字符
export function getLastEleven(value: string): any  {
  return getLastWord(value, 11);
}

// 获得字符串最后5个长度的字符
export function getLastFive(value: string): any  {
  return getLastWord(value, 5);
}

// 获得字符串最后5个长度的字符
export function getSidNoDash(value: string): any  {
  return value.replace(/-/g, "");
}
