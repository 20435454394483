export function getFirstWord(value: string, sublenght: number) {
  value = value + ""
  return value.substr(0, sublenght)
}

export function getShortWord(value: string, sublenght: number) {
  value = value + ""
  if (value.length <= sublenght) {
    return value
  }
  return value.substr(0, sublenght) + "..."
}

export function getLastWord(value: string, sublenght: number) {
  value = value + ""
  return value.substr(value.length - sublenght, sublenght)
}

export function clone(data: Array<any>) {
  let model: { [key: string]: any; } = {}
  for (let item in data) {
    if (data[item] instanceof Array) {
      model[item] = new Array();
      for (let i = 0; i < data[item].length; i++) {

        model[item].push(clone(data[item][i]));
      }
    } else {
      model[item] = data[item];
    }
  }
  return model;
}