<template>
  <v-card class="mx-3 my-3">
    <v-card-title class="pb-0">
      <v-radio-group v-model="export_type" row @change="items=selected=[]">
        <v-radio label="可生成" value="1"></v-radio>
        <v-radio label="已生成" value="2"></v-radio>
        <v-radio label="缺数据" value="3"></v-radio>
      </v-radio-group>
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="mr-2"
        :loading="loading"
        @click.stop="doSearch"
      >
        查询</v-btn
      >
      <v-dialog v-model="dialog" persistent max-width="400px">
        <template v-slot:activator="{ on }">
          <v-btn
            color="teal"
            class="mr-2"
            :disabled="export_type === '3' || selected.length === 0 || (export_type==='2' && select.type==='V')"
            :loading="loading"
            v-on="on"
          >
            生成报告</v-btn
          >
        </template>
        <v-form
          ref="formReport"
          v-model="valid"
          lazy-validation
          v-on:submit.prevent="onSubmit"
        >
          <v-card>
            <v-card-title>
              <v-label>生成报告任务</v-label>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-checkbox v-model="is_cover" label="是否生成封面"></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    height="32px"
                    label="报告日期"
                    v-model="report_date"
                    :rules="rules.report_date"
                    required
                    hint="格式YYYY-MM-DD"
                    v-if="export_type==='1'"
                  ></v-text-field>
                  <v-label v-else>您确认重新生成报告吗？</v-label>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    height="32px"
                    label="采样类型"
                    v-model="sample_type"
                    :rules="rules.sample_type"
                    required
                    v-if="select.type==='S' && export_type==='1'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <span class="caption error--text"
                          >*任务生成后，请到任务报告下载管理中查看执行状态</span
                        >
                </v-col>
              </v-row>
            </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="doSubmit">确认生成</v-btn>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >取消</v-btn
            >
          </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-btn
            color="green"
            class="mr-2"
            :disabled="export_type === '3' || selected.length === 0 || (export_type==='2' && select.type==='V') || select.tts_ready==='False'"
            :loading="loading"
            @click.stop="doConvert"
          >
            生成语音</v-btn
          >
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="4" sm="6" cols="12">
          <v-autocomplete
            v-model="select"
            :items="export_reports"
            item-text="report_name"
            item-value="no"
            label="报告模版"
            persistent-hint
            return-object
            single-line
            @change="doReportChange"
          ></v-autocomplete>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-autocomplete
            v-model="select_org"
            :items="orgs"
            item-text="org_name"
            item-value="sid"
            label="机构"
            persistent-hint
            return-object
            single-line
            @change="items=selected=[]"
          ></v-autocomplete>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-text-field
            v-model="search"
            label="客户编号/客户姓名/导入记录编号"
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :loading="loading"
            :expanded.sync="expanded"
            single-expand
            locale="zh-CH"
            item-key="sid"
            show-expand
            show-select
            :footer-props="{
              itemsPerPageText: '每页行数：',
              itemsPerPageOptions: pageItems
            }"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row dense>
                  <v-col
                    v-for="detail in item.details"
                    :key="detail.instance_no"
                    cols="12"
                  >
                    <span class="caption" v-if="detail.report_id"
                      >数据编号：{{
                        detail.instance_no | getLastTwelve
                      }}；数据模版名称：{{ detail.instance_name }}；报告编号：{{
                        detail.report_no
                      }}；报告名称：{{ detail.report_name }}</span
                    >
                    <span class="caption" v-else
                      >数据编号：{{
                        detail.instance_no | getLastTwelve
                      }}；数据模版名称：{{ detail.instance_name }}；发生日期：{{
                        detail.happen_date
                      }}</span
                    >
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.individual_gender="{ item }">
              <v-label>{{item.individual_gender === "MALE" ? "男" : "女"}}</v-label>
            </template>
            <template slot="no-data">
              <p class="text-xs-center grey--text">对不起，暂时无数据</p>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import {api} from "@/utils/api";
  import nf from "@/utils/notification";

  export default {
  name: "IndividualRecord",
  data() {
    return {
      export_reports: [],
      select: { report_name: "", no: "", type: "" },
      orgs: [],
      select_org: { org_name: "", sid: "" },
      export_type: "1",
      is_cover: false,
      search: "",
      loading: false,
      items: [],
      totalItems: 0,
      pageItems: [100, 200],
      selected: [],
      expanded: [],
      headers: [
        {
          value: "individual_no",
          text: "客户编号",
          sortable: false,
          breakpoints: []
        },
        {
          value: "individual_name",
          text: "姓名",
          sortable: false,
          breakpoints: []
        },
        {
          value: "org_name",
          text: "机构名称",
          sortable: false,
          breakpoints: []
        },
        {
          value: "doctor_name",
          text: "健康管理师",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "status_desc",
          text: "状态",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "data-table-expand",
          text: "",
          sortable: false,
          breakpoints: []
        }
      ],
      dialog: false,
      valid: true,
      rules: {
        report_date: [
          v => !!v || "不能为空，格式YYYY-MM-DD",
          v =>
            /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/.test(
              v
            ) || "日期格式错误"
        ],
        sample_type: [
           v => !!v || "不能为空，格式YYYY-MM-DD",
        ]
      },
      report_date: "",
      sample_type: "",
      post_data: {
        report_no: "",
        org_id: "",
        report_date: "",
        sample_type: "",
        is_cover: false,
        details: []
      }
    };
  },
  methods: {
    async doReportChange() {
      this.orgs = await api.get(
        "organizations/get_org_list_by_report/?r=" + this.select.no, null
      );
      this.select_org.sid = this.select_org.org_name = "";
      this.items=this.selected=[]
      // this.doSearch()
    },
    async doSearch() {
      this.loading = true;
      if (this.select.no === "") {
        nf.showErrorMsg("请选择报告模版");
        this.loading = false;
        return;
      }
      if (this.select_org.sid === "") {
        nf.showErrorMsg("请选择机构");
        this.loading = false;
        return;
      }
      let search_str = "";
      if (this.search) {
        search_str = this.search;
      } else {
        search_str = "";
      }
      try {
        this.items = await api.get(
          "operation_import_data_detail/get_individual_list_for_report/?" +
          "r=" +
          this.select.no +
          "&o=" +
          this.select_org.sid +
          "&t=" +
          this.export_type +
          "&search=" +
          search_str, null
        );
        this.totalItems = this.items.length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async doSubmit() {
       if (this.$refs.formReport.validate()) {
         this.post_data.report_no = this.select.no;
         this.post_data.org_id = this.select_org.sid;
         this.post_data.report_date = this.report_date;
         this.post_data.sample_type = this.sample_type;
         this.post_data.is_cover = this.is_cover;
         this.post_data.details = this.selected
         this.dialog = false
         await this.doSearch()
         nf.showInfoMsg('系统正在生成报告，稍后到报告下载管理去下载报告')
         await api.post('report_download_record/?', this.post_data)

         this.items=this.selected=[]

       }
    },
    async doConvert() {
      this.loading = true
         this.post_data.report_no = this.select.no;
         this.post_data.org_id = this.select_org.sid;
         this.post_data.details = this.selected
         
         nf.showInfoMsg('系统正在生成语音文件，稍后到报告下载管理去查看状态')
         await api.post('report_download_record/generate_audio/?', this.post_data)
          await this.doSearch()
         this.items=this.selected=[]
    }
  },
  async mounted() {
    this.export_reports = await api.get("report_templates/get_export_report_template/?", null);
  }
};
</script>
