<template>
  <v-container fluid class="pa-0">
    <v-navigation-drawer v-model="drawerRight" app clipped right>
      <v-card flat>
        <v-card-text>当前用户: {{ this.$store.getters.realName }} </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-list dense>
        <!-- <v-list-item @click.stop="right = !right">
          <v-list-item-action class="mr-2">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Open Temporary Drawer</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item @click.stop="dialog = true">
          <v-dialog v-model="dialog" persistent max-width="400px">
            <template v-slot:activator="{ on }">
              <v-list-item-action class="mr-4" v-on="on">
                <v-icon>mdi-key-change</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-on="on">修改密码</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-form ref="formPassword" v-model="valid" lazy-validation>
              <v-card>
                <v-card-title>
                  <span class="body-1">修改密码</span>
                  <span v-show="false">{{ isShow }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout row wrap>
                      <v-flex d-flex xs12>
                        <v-text-field
                          height="32px"
                          label="原密码"
                          v-model="password_info.old_password"
                          :rules="password_info.old_password_rules"
                          type="password"
                        ></v-text-field>
                      </v-flex>
                      <v-flex d-flex xs12>
                        <v-text-field
                          height="32px"
                          label="新密码"
                          v-model="password_info.new_password"
                          :rules="password_info.new_password_rules"
                          type="password"
                        ></v-text-field>
                      </v-flex>
                      <v-flex d-flex xs12>
                        <v-text-field
                          height="32px"
                          label="确认新密码"
                          v-model="password_info.confirm_new_password"
                          :rules="password_info.confirm_new_password_rules"
                          type="password"
                          :error="password_info.confirm_new_password_error"
                          :error-messages="
                            password_info.confirm_new_password_error_msg
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-flex d-flex xs12>
                        <span class="caption error--text"
                          >*密码修改成功后需要重新登录</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary darken-1" text @click="setPassword"
                    >确认修改</v-btn
                  >
                  <v-btn color="primary darken-1" text @click="dialog = false"
                    >取消</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-list-item>
        <v-list-item @click.stop="logout">
          <v-list-item-action class="mr-4">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>退出系统</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app fixed color="primary" clipped-left clipped-right dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <a :href="org.web" target="_blank">
        <v-avatar size="36px">
          <img :src="require(`@/assets/${org.code}.png`)" :alt="org.name"/>
        </v-avatar>
      </a>
      <v-toolbar-title class="pl-3">{{ org.name }} OSS</v-toolbar-title>
      <v-spacer />
      <v-btn icon class="white--text" @click.stop="setDark">
        <v-icon
          v-html="
            $vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-weather-sunny'
          "
        ></v-icon>
      </v-btn>
      <v-btn icon class="white-text" @click.stop="drawerRight = !drawerRight">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app clipped fixed>
      <v-list dense>
        <v-list-item to="/">
          <v-list-item-action class="mr-4">
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>首页</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div v-for="menu in menus" :key="menu.order_no">
          <v-list-item :to="menu.path">
            <v-list-item-action class="mr-4">
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ menu.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

<!--        <v-list-item to="/tools">-->
<!--          <v-list-item-action class="mr-4">-->
<!--            <v-icon>mdi-hammer-wrench</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>实用工具</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--        <v-list-item to="/about">-->
<!--          <v-list-item-action class="mr-4">-->
<!--            <v-icon>mdi-information</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>关于</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
      </v-list>
    </v-navigation-drawer>
    <v-main class="px-4 py-5">
      <breadcrumb :list="list" class="pl-3"></breadcrumb>
      <keep-alive>
        <router-view
          v-if="$route.meta.keepAlive && isRouterAlive"
        ></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive && isRouterAlive"></router-view>
    </v-main>
    <v-footer app inset>
      <span class="caption">&nbsp;FDS &copy; 2023</span>
      <v-spacer></v-spacer>
      <v-bottom-sheet v-model="showDownload" inset hide-overlay>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" small v-bind="attrs" v-on="on" v-show="taskCount > 0">
            下载管理({{taskCount}})
          </v-btn>
        </template>
        <DownloadBoard />
      </v-bottom-sheet>
      <v-spacer></v-spacer>
      <span class="caption">Powered by FDS&nbsp;</span>
    </v-footer>
  </v-container>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";
import { mapGetters, mapActions } from "vuex";
import Breadcrumb from "@/components/common/Breadcrumb";
import DownloadBoard from "@/components/DownloadBoard";

export default {
  props: {
    source: String,
  },
  components: { Breadcrumb, DownloadBoard },
  data: () => ({
    drawer: true,
    drawerRight: false,
    right: false,
    left: false,
    password_info: {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
      confirm_new_password_error: false,
      confirm_new_password_error_msg: "",
      old_password_rules: [(v) => !!v || "不能为空"],
      new_password_rules: [
        (v) => !!v || "不能为空",
        (v) => /^[a-zA-Z]/.test(v) || "密码开头字母只能是大/小写字母",
        (v) =>
          /^[_a-zA-Z0-9!@#$%^&*?]+$/.test(v) ||
          "密码只能包含大小写字母、数字、特殊字符(!@#$%^&*?)及下划线",
        (v) =>
          /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/.test(v) ||
          "密码长度8-16位，必须包含大小写字母及数字",
      ],
      confirm_new_password_rules: [
        (v) => !!v || "不能为空",
        (v) => /^[a-zA-Z]/.test(v) || "密码开头字母只能是大/小写字母",
        (v) =>
          /^[_a-zA-Z0-9!@#$%^&*?]+$/.test(v) ||
          "密码只能包含大小写字母、数字、特殊字符(!@#$%^&*?)及下划线",
        (v) =>
          /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/.test(v) ||
          "密码长度8-16位，必须包含大小写字母及数字",
      ],
    },
    dialog: false,
    valid: true,
    isShow: false,
    menus: [],
    isRouterAlive: true,
    showDownload: false,
    org: {name: '', code: '', web: ''}
  }),
  computed: {
    list() {
      return this.$route.matched.slice(1, this.$route.matched.length);
    },
    ...mapGetters({
      getDark: "dark",
      getDrawer: "drawer",
      getMini: "mini",
      getRight: "right",
      getLeft: "left",
      getClipped: "clipped",
      getFixed: "fixed",
    }),
    taskCount() {
      return Object.keys(this.$store.state.downloadTaskMap).length
    },
    isSuper() {
      return this.$store.state?.sessionUser?.user?.is_superuser
    }
  },
  watch: {
    '$store.state.showDownload'(val) {
      this.showDownload = val
    }
  },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  async mounted() {
    this.$vuetify.theme.dark = this.$store.getters.dark;
    this.menus = await api.get("users/get_menu_resources/?", null);
    const { result: org } = await api.get('users/get_org_info/?')
    this.org = org
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    async setPassword() {
      if (this.$refs.formPassword.validate()) {
        if (
          this.password_info.confirm_new_password !==
          this.password_info.new_password
        ) {
          this.password_info.confirm_new_password_error = true;
          this.password_info.confirm_new_password_error_msg =
            "两次输入的密码不一致";
          this.isShow = !this.isShow;
        } else if (
          this.password_info.confirm_new_password ===
          this.password_info.old_password
        ) {
          this.password_info.confirm_new_password_error = true;
          this.password_info.confirm_new_password_error_msg =
            "新密码不能与原密码相同";
          this.isShow = !this.isShow;
        } else {
          let result = await api.post(
            "users/change_password/?",
            this.password_info
          );
          console.log(result);
          if (result.code === 200) {
            nf.showSuccessMsg("密码修改成功，请重新登录！");
            await this.$store.dispatch("logout");
            await this.$router.replace("/login");
          } else {
            nf.showErrorMsg(result.message);
          }
          this.password_info.confirm_new_password_error = false;
          this.password_info.confirm_new_password_error_msg = "";
          this.isShow = !this.isShow;
        }
      }
    },
    ...mapActions({
      setDark: "dark",
      setDrawer: "drawer",
      setMini: "mini",
      setRight: "right",
      setLeft: "left",
      setClipped: "clipped",
      setFixed: "fixed",
    }),
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>
