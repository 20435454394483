import { ActionContext } from 'vuex'

export interface State {
  permissionList: []}
const state: State = {
  permissionList: [],
}

const mutations = {
  setPermissionList(state: State, result: any) {
    state.permissionList = result;
  }
}

const getters = {
  permissionList: (state: State) => state.permissionList
}

const actions = {
  setPermissionList(context: ActionContext<State, any>, result: any) {
    context.commit('setPermissionList', result)
  }
}

const permission = {
  state,
  mutations,
  getters,
  actions
}
export default permission;

