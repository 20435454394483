<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-0">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="11">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="营养素编号/营养素名称"
              hide-details
              @keyup.enter.native="doSearch"
              clearable
              @click:clear="doClearSearch"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-data-table
              :headers="getHeaders"
              :items="items"
              :options.sync="pagination"
              :server-items-length="totalItems"
              :loading="loading"
              locale="zh-CH"
              :footer-props="{
                itemsPerPageText: '每页行数：',
                itemsPerPageOptions: pageItems,
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.medicine_no">
                    <td>
                      <router-link
                        v-bind:to="{
                          name: '营养素明细',
                          params: { id: item.sid },
                        }"
                        class="v-breadcrumbs__item"
                      >
                        <!-- <router-link v-bind:to="'/customer/detail/'+item.sid" class="v-breadcrumbs__item"> -->
                        {{ item.medicine_no }}
                      </router-link>
                    </td>
                    <td>{{ item.medicine_name }}</td>
                    <td>{{ item.display_name }}</td>
                    <td>{{ item.pharmaceutical_factory }}</td>
                  </tr>
                </tbody>
              </template>
               <template slot="no-data">
                        <p class="text-xs-center grey--text">对不起，暂时无数据</p>
                    </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";

export default {
  name: "MedicineRecord",
  data() {
    return {
      dialog: false,
      dialog_delete: false,
      valid: true,
      totalItems: 0,
      items: [],
      org: {},
      actual_individual_tags: [],
      tags: {},
      loading: false,
      pagination: {},
      pageItems: [20, 50, 100],
      search: "",
      records: [],
      nextUrl: "",
      totalCount: 0,
      record_fields: [
        {
          value: "medicine_no",
          text: "编号",
          sortable: false,
          breakpoints: [],
        },

        {
          value: "medicine_name",
          text: "名称",
          sortable: false,
          breakpoints: [],
        },
        {
          value: "display_name",
          text: "别名",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
        {
          value: "pharmaceutical_factory",
          text: "厂商",
          sortable: false,
          breakpoints: ["xs", "sm"],
        },
      ],
      radios: 0,
    };
  },
  computed: {
    getHeaders() {
      let headers = [];
      for (let item of this.record_fields) {
        if (item.breakpoints.indexOf(this.$vuetify.breakpoint.name) < 0) {
          headers.push(item);
        }
      }
      return headers;
    },
  },
  watch: {
    pagination: {
      async handler() {
        const { page, itemsPerPage } = this.pagination;
        if (this.nextUrl && this.records.length < page * itemsPerPage) {
          this.loading = true;
          let url = this.nextUrl.split("?");
          try {
            let data = await api.get(
              "medicines/?" + url[url.length - 1],
              this.search
            );
            for (let item of data["results"]) {
              this.records.push(item);
            }
            this.nextUrl = data["next"];
            this.totalCount = data["count"];
            this.updateDataTable(this.records, this.totalCount);
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        } else {
          this.updateDataTable(this.records, this.totalCount);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    try {
      let result = await api.get("medicines/?");
      this.loading = false;
      this.records = result["results"];
      this.nextUrl = result["next"];
      this.totalCount = result["count"];
      this.pagination.page = 1
      this.updateDataTable(this.records, this.totalCount);
    } catch (error) {
      this.loading = false;
    }
  },
  methods: {
    updateDataTable(records, count) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let items = records;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy[0]];
          const sortB = b[sortBy[0]];

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }
      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      }
      this.items = items;
      this.totalItems = count;
    },

    async doSearch() {
      this.loading = true;
      try {
        let result = await api.get("medicines/?search=" + this.search);

        this.loading = false;
        this.records = result["results"];
        this.nextUrl = result["next"];
        this.totalCount = result["count"];
        this.pagination.page = 1
        this.updateDataTable(this.records, this.totalCount);
      } catch (error) {
        this.loading = false;
      }
    },
    async doClearSearch() {
      this.search = ""
      this.doSearch()
    },
  },
};
</script>
