import store from '@/store'
import router from '@/router'
import request from "@/utils/request";
import nf from "../utils/notification";


export default {
    loggedIn() {
        let data = {
            token: store.getters.token
        };
        if (store.getters.token) {
            let url = 'api-token-verify/';
            request.post(url, data).then(r => {
            }).catch(err => {
                nf.showWarnMsg("你的会话已经超时，请重新登录");
                store.dispatch("logout");
                router.replace("/login");
            });
        }
        else {
            store.dispatch("logout");
            router.replace("/login");
        }
        return store.getters.loggedIn
    },
}