import { ActionContext } from 'vuex'

export interface State {
  logSearch: ''}
const state: State = {
  logSearch: '',
}

const mutations = {
  setLogSearch(state: State, result: any) {
    state.logSearch = result;
  },
  clearLogSearch(state: State) {
    state.logSearch = ''
  }
}

const getters = {
  logSearch: (state: State) => state.logSearch
}

const actions = {
  setLogSearch(context: ActionContext<State, any>, result: any) {
    context.commit('setLogSearch', result)
  },
  clearLogSearch(context: ActionContext<State, any>) {
    context.commit('clearLogSearch')
  }
}

const params = {
  state,
  mutations,
  getters,
  actions
}
export default params;

