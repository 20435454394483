import Vue from "vue";

function showMsg(msg_type: string, titile: string, msg: string) {
  Vue.notify({
    // (optional)
    // Name of the notification holder
    group: "foo",

    // (optional)
    // Class that will be assigned to the notification
    type: msg_type,

    // (optional)
    // Title (will be wrapped in div.notification-title)
    title: titile,

    // Content (will be wrapped in div.notification-content)
    text: msg,

    // (optional)
    // Overrides default/provided duration
    duration: 3000,

    // (optional)
    // Overrides default/provided animation speed
    speed: 1000,

    // (optional)
    // Data object that can be used in your template
    data: {}
  });
}

export default {
  showInfoMsg(msg: string) {
    showMsg("info", "提示", msg);
  },
  showSuccessMsg(msg: string) {
    showMsg("success", "提示", msg);
  },
  showWarnMsg(msg: string) {
    showMsg("warn", "提示", msg);
  },
  showErrorMsg(msg: string) {
    showMsg("error", "提示", msg);
  }
};