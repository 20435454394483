import Vue from 'vue'
import Vuex from 'vuex'
import sessionUser from './modules/sessionUser'
import layout from "./modules/layout"
import params from "./modules/params"
import permission from "./modules/permission";

import createPersistedState from "vuex-persistedstate";
import nf from "@/utils/notification"

Vue.use(Vuex)

interface DownloadTask {
  url: string                // 下载地址 作为key
  name: string               // 任务名称
  method: string             // 请求类型
  data: string               // 接口附加参数
  total: number              // 文件大小
  cancel: Function           // 主动取消下载的方法
  startDate: Date            // 开始时间
  endDate?: Date             // 结束时间
  percentage: number         // 进度
  status: number             // 状态  [-1, 0, 1]  [下载中，失败，成功]
  statusDesc: string         // 状态描述
}

export default new Vuex.Store({
  state: {
    // 全局下载任务
    downloadTaskMap: <DownloadTask>{},
    // 显示下载管理窗体
    showDownload: false
  },
  mutations: {
    startDownload(state, { name, url, method, data, cancel }) {
      const downloadTaskMap = { ...state.downloadTaskMap }
      if (downloadTaskMap[url]) {
        state.showDownload = true
        nf.showInfoMsg('已存在相同任务')
        return
      }
      downloadTaskMap[url] = {
        name,
        method,
        data,
        cancel,
        startDate: new Date(),
        endDate: undefined,
        percentage: 0,
        status: -1,
        statusDesc: ''
      }
      state.downloadTaskMap = downloadTaskMap
      nf.showSuccessMsg('下载任务添加成功')
    },
    percentUpdate(state, { url, loaded, total }) {
      const downloadTaskMap = { ...state.downloadTaskMap }
      const task = downloadTaskMap[url]
      task.total = total
      task.percentage = Number(((loaded / total) * 100).toFixed(2))
      state.downloadTaskMap = downloadTaskMap
    },
    downloadComplete(state, url) {
      const downloadTaskMap = { ...state.downloadTaskMap }
      const task = downloadTaskMap[url]
      task.status = 1
      task.endDate = new Date()
      state.downloadTaskMap = downloadTaskMap
    },
    downloadFail(state, { url, desc }) {
      const downloadTaskMap = { ...state.downloadTaskMap }
      const task = downloadTaskMap[url]
      task.status = 0
      task.statusDesc = desc
      state.downloadTaskMap = downloadTaskMap
      state.showDownload = true
    },
    deleteDownloadTask(state, url) {
      const downloadTaskMap = { ...state.downloadTaskMap }
      delete downloadTaskMap[url]
      state.downloadTaskMap = downloadTaskMap
      if (Object.keys(downloadTaskMap).length === 0) {
        state.showDownload = false
      }
    },
    vmodelDownload(state, flag) {
      state.showDownload = flag
    }
  },
  modules: {
    sessionUser,
    layout,
    params,
    permission
  },
  plugins: [
    createPersistedState({
      key: "xx",
      paths: ["sessionUser", "layout"]
    })
  ]
});
