<template>
  <v-card class="mx-3 my-3">
    <v-card-title>
      <v-label>{{ record.import_name }}--{{ record.import_no }}</v-label>
      <v-spacer></v-spacer>
      <v-btn color="grey" class="hidden-sm-and-down" @click="doBack">
        返回
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="3" sm="6" cols="12"
          ><v-label
            >操作时间：{{ record.created_time | formatTimeLong }}</v-label
          ></v-col
        >
        <v-col md="3" sm="6" cols="12"
          ><v-label>操作者：{{ record.created_by }}</v-label></v-col
        >
        <v-col cols="12">
          <v-expansion-panels v-model="panel" multiple flat hover>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0 body-2"
                >原始数据（共计
                {{ data.length }} 条记录）</v-expansion-panel-header
              >
              <v-expansion-panel-content class="px-0">
                <v-simple-table fixed-header dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th v-for="head in heads" :key="head">{{ head }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in data" :key="index">
                        <td v-for="(value, index) in item" :key="index">
                          <v-label>{{ value }}</v-label>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0 body-2"
                >导入明细（共计 {{ items.length }} 条记录）
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalItems"
                  :loading="loading"
                  :expanded.sync="expanded"
                  single-expand
                  locale="zh-CH"
                  item-key="sid"
                  show-expand
                  dense
                  :footer-props="{
                    itemsPerPageText: '每页行数：',
                    itemsPerPageOptions: pageItems
                  }"
                >
                  <template v-slot:expanded-item="{ headers, item }" v-if="record.import_type!=='CUSTOMER' && record.import_type!=='4H'">
                    <td :colspan="headers.length">
                      <v-row dense>
                        <v-col
                          v-for="instance in item.instance
                            .template_instances"
                          :key="instance.sid"
                          cols="12"
                        >
                          <span class="caption" v-if="instance.character_detail"
                            >{{ instance.character.character_name }}:
                            {{ instance.character_detail.detail_name }} ({{
                              instance.character_detail.detail_value
                            }})</span
                          >
                          <span class="caption" v-else
                            >{{ instance.character.character_name }}:
                            {{ instance.instance_value }}
                          </span>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }" v-else>
                    <td :colspan="headers.length">
                      <v-row dense>
                        <v-col cols="12">
                          <span class="caption">
                            编号: {{ item.individual.individual_no }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span class="caption">
                            姓名: {{ item.individual.individual_name }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span class="caption">
                            性别: {{ item.individual.individual_gender }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span class="caption">
                            出生日期: {{ item.individual.individual_birthday }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span class="caption">
                            手机号码: {{ item.individual.phone_number }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span class="caption">
                            识别号: {{ item.individual.individual_additional_no }}
                          </span>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                  <template v-slot:item.detail_no="{ item }">
                    <v-label>{{ item.detail_no | getLastFive }}</v-label>
                  </template>
                  <template v-slot:item.individual.individual_gender="{ item }">
                    <v-label>{{
                      item.individual.individual_gender === "MALE" ? "男" : "女"
                    }}</v-label>
                  </template>
                  <template v-slot:item.instance_no="{ item }">
                    <v-label>{{
                      item.instance_no
                    }}</v-label>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { api } from "@/utils/api";
import nf from "@/utils/notification";

export default {
  name: "ViewDataImportRecord",
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      showDialog: false,
      record: {},
      org_name: "",
      doctor_name: "",
      heads: [],
      data: [],
      panel: [1],
      items: [],
      records: [],
      loading: true,
      headers: [
        // {
        //   value: "detail_no",
        //   text: "编号",
        //   sortable: false,
        //   breakpoints: [],
        // },

        {
          value: "individual.individual_name",
          text: "客户姓名",
          sortable: false,
          breakpoints: []
        },
        {
          value: "individual.individual_gender",
          text: "性别",
          sortable: false,
          breakpoints: []
        },
        {
          value: "individual.individual_birthday",
          text: "出生日期",
          sortable: false,
          breakpoints: []
        },
        {
          value: "individual.individual_no",
          text: "客户编号",
          sortable: false,
          breakpoints: []
        },
        {
          value: "instance_no",
          text: "编号",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "instance_name",
          text: "名称",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "status",
          text: "状态",
          sortable: false,
          breakpoints: ["xs", "sm"]
        },
        {
          value: "data-table-expand",
          text: "",
          sortable: false,
          breakpoints: []
        }
      ],
      totalItems: 0,
      pagination: {},
      pageItems: [10, 20, 50],
      nextUrl: "",
      totalCount: 0,
      expanded: []
    };
  },
  watch: {
    pagination: {
      async handler() {
        const { page, itemsPerPage } = this.pagination;
        // const { sortBy, sortDesc, page, itemsPerPage } = this.pagination
        if (this.nextUrl && this.records.length < page * itemsPerPage) {
          this.loading = true;
          let url = this.nextUrl.split("?");
          try {
            let result = api.get(
              "operation_import_data_record/" +
                this.$route.params.id +
                "/get_record_detail/?" +
                url[url.length - 1],
              this.loading
            );
            for (let item of result["results"]) {
              this.records.push(item);
            }
            this.nextUrl = result["next"];
            this.totalCount = result["count"];
            this.updateDataTable(this.records, this.totalCount);
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        } else {
          this.updateDataTable(this.records, this.totalCount);
        }
      },
      deep: true
    }
  },
  methods: {
    doBack() {
      this.$router.replace({
        name: "记录列表",
        params: {}
      });
    },
    updateDataTable(records, count) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let items = records;

      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      }
      this.items = items;
      this.totalItems = count;
    },
    async doLoadData() {
      this.loading = true;
      try {
        let result = await api.get(
          "operation_import_data_record/" +
            this.$route.params.id +
            "/get_record_detail/?"
        );
        this.loading = false;
        this.records = result["results"];
        this.nextUrl = result["next"];
        this.totalCount = result["count"];
        this.updateDataTable(this.records, this.totalCount);
      } catch (error) {
        this.loading = false;
      }
    }
  },

  async mounted() {
    this.record = await api.get(
      "operation_import_data_record/" + this.$route.params.id + "/get_record/?"
    );
    this.data = this.record.raw_data.slice(1, this.record.length);
    this.heads = this.record.raw_data[0];
    this.doLoadData();
  }
};
</script>
