<template>
	<v-container fluid>
		<v-tabs>
			<v-tab key="customer_info">
				客户信息
			</v-tab>
			<v-tab key="all_data">指标数据</v-tab>
			<v-tab-item key="customer_info">
				<customer-export></customer-export>
			</v-tab-item>
			<v-tab-item key="all_data">
				<all-data-export></all-data-export>
			</v-tab-item>
		</v-tabs>
	</v-container>
</template>

<script>
  import CustomerExport from "@/components/data_export/CustomerExport";
  import AllDataExport from "@/components/data_export/AllDataExport";

  export default {
    name: "DataExport",
    components: {
      CustomerExport,
      AllDataExport
    },
    data() {
      return {
        data_type: "1",
        loading: false
      };
    },
    methods: {
      doSearch() {
      }
    }
  };
</script>
