<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col xl="4" lg="4" md="6" sm="8" xs="12">
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>FUTURE DATA SERVICE</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  class="white--text"
                  @click.stop="$vuetify.theme.dark = !$vuetify.theme.dark"
                >
                  <v-icon v-html="$vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-weather-sunny'"></v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text v-if="isCompatible">
                <v-form>
                  <v-text-field prepend-icon="person" name="login" label="username" type="text"
                                v-model="username"
                                placeholder="username"></v-text-field>
                  <v-text-field prepend-icon="lock" name="password" label="password" id="password"
                                v-model="pass"
                                placeholder="password"
                                type="password"
                                @keyup.enter.native="login"></v-text-field>
                  <div class="sms">
                    <v-text-field
                      prepend-icon="mdi-relative-scale"
                      name="imgcode"
                      label="validation code"
                      v-model="imgcode"
                      @keyup.enter.native="login"
                    >
                    </v-text-field>
                    <img style="width:110px;margin-left:8px;" :src="`/api/imgcode/?r=${r}`" @click="r = Date.now()"/>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-text v-if="!isCompatible" class="px-6 py-12 text-center">
                <span class="caption error--text" >您的浏览器不兼容，请使用Chrome/Safari/Firefox/Edge等兼容浏览器访问</span>
              </v-card-text>
              <v-card-actions v-if="isCompatible">
                <v-spacer></v-spacer>
                <v-btn color="primary white--text" @click="login" v-if="isCompatible">login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import nf from "@/utils/notification";
import {api} from "@/utils/api"

export default {
  name: "Login",
  data() {
    return {
      username: this.$store.getters.userName,
      pass: "",
      imgcode: '',
      dark: false,
      isCompatible: false,
      r: Date.now()
    };
  },
  computed: {
    ...mapGetters({
      getDark: "dark"
    })
  },
  mounted() {
    let userAgent = navigator.userAgent.toLowerCase()
    this.isCompatible = userAgent.indexOf('edge/') > -1 || userAgent.indexOf('applewebkit/') > -1 || userAgent.indexOf('chrome/') > -1 || userAgent.indexOf('firefox/') > -1
    this.$vuetify.theme.dark = this.$store.getters.dark
  },
  methods: {
    ...mapActions({
      setDark: "dark"
    }),

    async login() {
      if (!!!this.pass || !!!this.username) {
        nf.showWarnMsg("请输入用户名或口令");
        return;
      }
      if (this.imgcode.length !== 4) {
        nf.showWarnMsg("图形验证码错误");
        return;
      }
      let postData = {
        imgcode: this.imgcode,
        username: this.username,
        password: this.pass,
        is_sms: false,
      };

      let result = await api.post('api-token-auth/', postData)
      if (result.error) {
        nf.showWarnMsg(result.error)
        this.r = Date.now()
        return
      }
      if (result) {
        await this.$store.dispatch("setUserInfo", result);
        await this.$router.replace("/");
        // this.$store.dispatch("setUserInfo", result).then(()=>{
        //   this.$router.replace("/");
        // })
      }
    }
  }
};
</script>

<style scoped>
  .container.fill-height {
    align-items: center;
  }
  .sms {
    display: flex;
    align-items: center;
  }
</style>
