<template>
  <template-download></template-download>
</template>

<script>
// @ is an alias to /src
import TemplateDownload from '@/components/TemplateDownload.vue'

export default {
  name: 'Home',
  components: {
    TemplateDownload
  }
}
</script>
