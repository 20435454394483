  import XS from 'xlsx'

  const make_cols = function (ref_str: string) {
    return Array(XS.utils.decode_range(ref_str).e.c + 1)
      .fill(0)
      .map((x, i) => ({ name: XS.utils.encode_col(i), key: i }));
  }

export const excel2Json = function(file: File, sheetIndex = 0, dateNF = 'yyyy/mm/dd') {
  return new Promise((r, j) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (!e) {
        return j(e)
      }
      /* Parse data */
      const b_str = e.target?.result
      const wb = XS.read(b_str, {
        type: "binary",
        cellText: false,
        cellDates: true
      })
      const ws_name = wb.SheetNames[sheetIndex]
      const ws = wb.Sheets[ws_name]
      /* Convert array of arrays */
      const data: any = XS.utils.sheet_to_json(ws, {
        header: 1,
        raw: false,
        dateNF
      })

      const headLength = data[0].length
      data.map((d: any) => {
        if (d.length < headLength) {
          d.push(...(new Array(headLength - d.length)))
        }
      })

      r({ data, cols: make_cols(ws["!ref"] || '') })
    }
    reader.readAsBinaryString(file)
  })
}