import request from "@/utils/request";
import nf from "@/utils/notification"
import axios from "axios";
import store from "@/store";

const api = {
  async get(url: string, options: any) {
    try {
      let res = await request.get(url, options);
      // res = res.data;
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (err) {
      nf.showErrorMsg(err);
    }
  },
  async post(url: string, data: any): Promise<any> {
    try {
      let res = await request.post(url, data);
      // res = res.data;
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (err) {
      nf.showErrorMsg(err);
      // console.log(err);
    }
  },

  async put(url: string, data: any) {
    try {
      let res = await request.put(url, data);
      // res = res.data;
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (err) {
      nf.showErrorMsg(err);
    }
  },

  async delete(url: string) {
    try {
      let res = await request.delete(url);
      // res = res.data;
      return new Promise(resolve => {
        resolve(res);
      });
    } catch (err) {
      nf.showErrorMsg(err);
    }
  },
  async download(config: { name: string, url: string, method: string, data: any, cancel?: any }) {
    const cancelToken = new axios.CancelToken((cancel) => {
      config.cancel = cancel
      store.commit('startDownload', config)
    })
    const instance = axios.create({
      responseType: "blob",
      headers: {
        Authorization: `Martin ${store.getters.token}`
      },
      timeout: Infinity,
      cancelToken,
      onDownloadProgress(e) {
        store.commit('percentUpdate', {
          url: config.url,
          loaded: e.loaded,
          total: e.total
        })
      }
    })
    instance[config.method](config.url, config.data).then((result: any) => {
      const downdloadFileName = decodeURIComponent(result.headers['content-disposition'].split(';')[1].split('filename=')[1])
      // 文件大小校验
      const fullSize = result.headers['content-length']
      if (result.data.size != fullSize) {
        throw '文件传输异常'
      }

      const downloadLink = window.URL.createObjectURL(new Blob([result.data], { type: `application/${downdloadFileName.split('.').pop}` }));
      let link = document.createElement("a");
      link.href = downloadLink;
      link.setAttribute("download", downdloadFileName);
      link.click();

      store.commit('downloadComplete', config.url)
    }).catch(async (e: any) => {
      // 带异步的错误处理
      const error: any = await new Promise(r => {
        if (e.response) {
          const reader = new FileReader();
          reader.readAsText(e.response.data, 'utf-8');
          reader.onload = function () {
              const errorMsg = JSON.parse(reader.result as string);
              r(errorMsg.message)
          }
        } else {
          r(e)
        }
      })
      if (`${error}` === 'Cancel') { return }
      console.error(error)
      const msg = error.message || error || '未知错误'
      store.commit('downloadFail', { url: config.url, desc: msg})
      nf.showErrorMsg(msg)
    });
  }
};
export { api };