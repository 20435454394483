var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"编号/状态","hide-details":"","clearable":""},on:{"click:clear":_vm.doSearchClear},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"persistent":"","max-width":"320"},model:{value:(_vm.dialog_delete),callback:function ($$v) {_vm.dialog_delete=$$v},expression:"dialog_delete"}},[(_vm.dialog_delete)?_c('v-card',[_c('v-card-title',{staticClass:"subtitle-2"},[_vm._v("您确认要删除该记录吗?")]),_c('v-card-text',[_vm._v("删除后该记录将无法还原，请您谨慎操作！")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},nativeOn:{"click":function($event){_vm.dialog_delete = false}}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"error","text":""},nativeOn:{"click":function($event){return _vm.doDelete($event)}}},[_vm._v("我想好了，删")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"320"},model:{value:(_vm.dialog_download),callback:function ($$v) {_vm.dialog_download=$$v},expression:"dialog_download"}},[(_vm.dialog_download)?_c('v-card',[_c('v-card-text',[_vm._v("正在下载文件，请稍等...")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalItems,"loading":_vm.loading,"expanded":_vm.expanded,"single-expand":"","show-expand":"","item-key":"sid","locale":"zh-CH","footer-props":{
              itemsPerPageText: '每页行数：',
              itemsPerPageOptions: _vm.pageItems
            }},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"dense":""}},_vm._l((item.details),function(detail){return _c('v-col',{key:detail.individual_no,attrs:{"cols":"12"}},[_c('span',{staticClass:"caption"},[_vm._v("姓名："+_vm._s(detail.individual_name)+"；客户编号："+_vm._s(detail.individual_no)+"；报告编号："+_vm._s(_vm._f("getLastEleven")(detail.report_no)))])])}),1)],1)]}},{key:"item.created_time",fn:function(ref){
            var item = ref.item;
return [_c('v-label',[_vm._v(_vm._s(_vm._f("formatTimeLong")(item.created_time)))])]}},{key:"item.created_by",fn:function(ref){
            var item = ref.item;
return [_c('v-label',[_vm._v(_vm._s(item.created_by.first_name))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.file_url.length > 0)?_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v(" mdi-download ")]):_vm._e(),(item.status!=='ongoing')?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.doDeleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])},[_c('template',{slot:"no-data"},[_c('p',{staticClass:"text-xs-center grey--text"},[_vm._v("对不起，暂时无数据")])])],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }